
.spu-table {
  margin-top: 5px;
  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;
  }
  td, th {
    border: 1px solid #ccc;
    line-height: 42px;
    vertical-align: middle;
  }
  input {
    width: 100%;
    border: 0 none;
    text-align: center;
    box-sizing: border-box;
  }
  .auto-input {
    width: auto;
  }
  thead {
    background-color: #ebebeb;
  }
  .table-vector,
  .table-update-none td,
  .table-update-some .grid-sku {
    background: #f8f8f8;
  }
  tbody tr {
    background-color: #fff;
    #spu-detail-table & {
      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
}

#spu-table-form-wrap {
  width: 100%;
}

