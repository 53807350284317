.welcome-title{
  width:100%;
  height:20px;
  text-align:center;
  line-height:20px;
  margin:90px 0 85px;
  font-size:20px;
  font-weight:700;
  color:#333333;
}

%system {
  display: block;
  width: 140px;
  margin: 0 auto;
  padding-top: 160px;
  padding-bottom: 10px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

%system-wrap {
  width: 260px;
}

.welcome {
  margin: 0 auto;
  width: 260px * 4;
  font-size: 0;
  text-align: center;
  li {
    font-size: 14px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-top: 20px;
    @extend %system-wrap;
    a {
      @extend %system;
      &:hover {
        //color: #000 !important;
        background-position: -140px 0;
      }
    }
  }
  .welcome-system-help {
    margin-top: 0;
  }
}

