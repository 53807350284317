.focus-register-main-content{

  .focus-register-edit-view{
      width:100%;
    display: none;

    .focus-register-edit{
      margin: 50px 0px 0px 20px;
      line-height: 30px;
      width:fit-content;

      .first-focus-settings, .register-settings {
        clear: both;
        li{
          margin-left:30px;
          label{
            float:left
          }
          input{
            float: right;
          }
        }
      }
    }
    .focus-register-toolbar{
      text-align: center;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }

  .focus-register-detail{
    margin: 50px 0px 0px 20px;
    line-height: 30px;
    width:fit-content;

    .first-focus-settings, .register-settings {
      clear: both;
      li{
        margin-left:30px;
        label{
          float:left
        }
        span{
          float: right;
        }
      }
    }
  }

  button{
    font: inherit;
    font-size: 12px;
    line-height: 28px;
    vertical-align: middle;
    padding: 0 20px;
    border: 1px solid #878787;
    border-radius: 4px;
    color: #666;
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
    cursor: pointer;
    &:hover {
      background: #fff;
    }
    &:focus {
      outline: none;
    }
  }
}


