$color-explain:#878787;
$font-size-type1:12px;
$font-size-type2:14px;
.flex-style{
  display:flex;
  align-items: center;
}
.employees {
  margin: 20px 0;
  text-align: justify;
  font-size: 0;
  %employee {
    @extend %inline-block;
    width: 33%;
    vertical-align: top;
  }
  .employee {
    @extend %employee;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 14px;
    background-color: #f8f8f8;
    border-radius: 4px;
    padding: 24px 30px;
  }
  .helper {
    @extend %employee;
    height: 0;
    line-height: 0;
    overflow: hidden;
  }
  h1 {
    font-size: 14px;
    line-height: 2;
    display:flex;
    &.employee-owner {
      color: $title-color;
    }
  }
  dl {
    overflow: hidden;
  }
  dt, dd {
    float: left;
    line-height: 22px;
    font-size: 12px;
  }
  dt {
    color: #878787;
    clear: both;
  }
  dd {
    color: #333;
  }
  .actions {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 0;
  }
  .action-edit,
  .action-delete {
    @extend %inline-block;
    margin-left: 10px;
    cursor: pointer;
  }
  .download-qrcode {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}

.shop-detail {
  margin: 0 0 20px;
  background-color: #f8f8f8;
  padding: 30px;
  h1 {
    font-size: 14px;
    color: $title-color;
  }
  dl {
    float: left;
  }
  dt, dd {
    float: left;
    height: 28px;
    margin-top: 15px;
    line-height: 28px;
  }
  dt {
    font-size: 12px;
    color: #878787;
    margin-right: 10px;
    .register-time & {
      margin-right: 0;
    }
  }
  dd {
    height: 26px;
    color: #666;
    font-size: 12px;
    margin-right: 50px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #fff;
    padding: 0 10px;
  }
  .address {
    clear: both;
  }
}
#type-two,
#type-three{
  display: none;
}
.text-left{
  text-align: left;
  float: left;
  line-height: 30px;
  margin-right: 15px;
}

.form-group .entity-store,.form-group .ecommerce-shop{
  display:inline-block;
  width:80px;
  height:30px;
  background:#fff;
  text-align:center;
  font-size:$font-size-type2;
  margin-bottom:10px;
  border-radius: 2px;
}
.form-group .entity-store{margin-left: 40px}

.form-group .current-active{
  background:#91419d;
  color:#fff;
}
.explain-title{
  margin-left:40px;
  color:$color-explain;
  font-size:$font-size-type1;
}
.ecommerce-shop-content{
  display:none;
}
.get-goods-box{
  margin-bottom:8px;
  display:flex;
}
#input-km{
  border:0;
  border-bottom:1px solid #333;
  width:40px;
  outline: none;
  background:transparent;
}
.checkbox-other{
  width:20px;
  height:20px;
}
.checked-type{
  background:url(../images/selected.jpg) no-repeat center;
}
.express-delivery,.get-goods{
  display:flex;
  img{
    margin-right:7px;
  }
}
.img_preview{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.user-load-img{width:100%; height:100%; display:block;visibility: hidden}
.overrule-content{
  display:none;
  background: #f6f6f6;
  width: 300px;
  height: 200px;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  textarea{
    width: 200px;
    height: 100px;
    margin-left: 40px;
    margin-top: 30px;
  }
  div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .cancel-btn,.confirm-btn{
      cursor: pointer;
    }
  }
}
.checkbox-style1{
  width:23px;height:23px;margin-right:10px
}
.span-style1{
  display: inline-block;width: 120px;text-align: right;
}
.input-style1{
  width:200px;height: 28px;line-height: 28px;border: 1px solid #e0e0e0;border-radius: 2px;padding: 0 10px;box-sizing: border-box;
}
.save-style1{
  margin-left: 245px;margin-top: 20px;padding: 10px 25px;display: inline-block;color: #fff;background-color: #cc73cf;border: none;border-radius: 4px;cursor: pointer;
}
