#ic-table-list{
  tr:nth-child(2n){
    background: #f8f8f8;
  }
  tr:hover{
    background: #e6e6e6;
  }
  thead{
    height: 42px;
  }
  .table{
    width: 100%;
  }
  .tr{
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  th{
    height: 42px;
    line-height: 42px;
    color: #505050;
    font-size: 12px;
    font-weight: 700;
    background: #ebebeb;
    text-align: center;
    position: relative;
  }
  .td{text-align: center;
    font-size: 12px;
    color:#666666;
    margin: 0;
  }
  .ic-ul{
    position: relative;
  }
  .ic-ul ul{
    display: none;
    margin: 0;
    list-style: none;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
  }
  .ic-ul ul li{
    font-size: 12px;
    font-weight: 400;
    height: 50px;
    line-height: 48px;
    border:1px solid #ebebeb;
    border-top: 0;
    background: white;
    color: #666666;
  }
  .ic-ul ul li{
    font-size: 12px;
    font-weight: 400;
    height: 30px;
    line-height: 28px;
    border: 1px solid #ebebeb;
    border-top: 0;
    background: white;
    color: #666666;
  }
  .ic-ul ul li:hover{
    background: #cc73cf;
    color: white;
  }
  th.active{
    background: #c6c6c6;
  }
  .active ul{
    display: block;
  }
}
.ic-pay-button{
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.ic-time-button{
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.ic-navi-hidden{
  height: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}
#ic-site-content{
  padding: 20px;
  z-index: 10;
  position: fixed;
  top: 69px;
  right: 1px;
  border-radius: 3px;
  border: 1px solid #333;
  background: white;
  display: none;
}
#ic-pay-content{
  padding: 20px;
  z-index: 10;
  position: fixed;
  top: 69px;
  right: 1px;
  border-radius: 3px;
  border: 1px solid #333;
  background: white;
  display: none;
}
#payButton,
#timeButton{
  color:white;
  background: #cc73cf;
}

#member-pay-out-box::-webkit-scrollbar{
  width:0;
}
#member-pay-out-box{
  position: absolute;
  top:66px;
  right: 30px;
  background: #fff;
  border:1px solid #ccc;
  border-bottom: none;
  border-radius: 4px;
  max-height: 500px;
  overflow-y: scroll;
  #member-pay-out-content{
    .pay-out-cell{
      height:65px;
      border-bottom:1px solid #ccc;
      padding: 10px 15px;
      box-sizing: border-box;
      .pay-out-left{
        display: inline-block;
        p:first-of-type{
          font-size: 17px;
        }
        p:nth-last-of-type{
          font-size: 12px;
        }
      }
      .pay-out-right{
        line-height: 45px;
        float: right;
      }
    }
  }
}
.user_edit-select{
  margin-left: 20px;
  color: #cc73cf;
  cursor: pointer;
}