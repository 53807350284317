.task-container {
  position: relative;
  margin-bottom: 20px;
}

.task-form .textarea-wrap {
  width: 580px;
}

.task-form .duty {
  width: 100%;
  min-height: 90px;
  line-height: 22px;
  font-size: 14px;
  border-radius: 2px;
  resize: none;
  padding: 5px 10px;
}

.task-form {
  margin-bottom: 0;
}

.task-form .checkbox-group .box,
.task-form .radio-group .box {
  width: 14px;
  height: 14px;
  margin: 6px 3px 0 0;
}

.task-container .ops {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
  display: none;
}

.task-container .ops a {
  margin-left: 15px;
}

.task-container.disabled form {
  opacity: 0.5;
  pointer-events: none;
}

.task-container.disabled .submit-btn {
  display: none;
}

.task-container.disabled .ops {
  display: block !important;
}

