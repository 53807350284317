$bg: #f8f8f8;
.width{
  width: 100% !important;
  box-sizing: border-box;
}
.new-order_btn{
  line-height: 26px;
  box-sizing: border-box;
  border: 1px solid #a52f9c;
  border-radius: 3px;
  display: block;
  padding: 0 10px;
  color: #fff;
  background: #a52f9c;
  cursor: pointer;
  margin: 0 auto;
  font-size: 12px;
}
.money-before{
  display: inline-block;
  width: 23px;
  text-align: right;
}
// input
#coupon-code-form #coupon-code,
#code-form #code,
#code-form-ic #code-ic {
  width: 160px;
  padding: 6px 4px;
  margin-right: 20px;
}
#code-form-ic{
  margin-left: 20px;
}

  // user/order
.brief-section {
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: $bg;
}
.user-default-view-content {
  padding: 0 30px;
}
.order-detail-item-foot,
.order-detail-item-head{
  list-style: none;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background: #f8f8f8;
  padding: 0 0 0 30px;
}
.order-detail-item-foot{
  text-align: right;
}
// user-default
.user-default-view-content {
  min-height: 126px;
  background: $bg url(../images/order-member-empty.png) no-repeat 50% 50%;
}

#order-form-content-section .order-user-brief {
  padding: 0 30px;
}
#order-form-content-section{
  padding-bottom: 130px;
  margin-bottom: 200px;
}

// user-detail
.order-view-brief,
.order-user-brief {
  overflow: hidden;
  background-color: #f8f8f8;
  padding: 0 30px 10px;
  dl {
    margin-top: 8px;
    float: left;
  }
  dt, dd {
    float: left;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
  }
  dt {
    color: #878787;
    .register-time & {
      margin-right: 0;
    }
  }
  dd {
    height: 26px;
    color: #666;
    font-size: 14px;
    padding: 0 10px;
  }
}

#order-detail-view-content {
  max-height: 340px;
  overflow-y: auto;
}

// sku-default
#order-default-view-content {
  height: 150px;
  background: $bg url(../images/order-code-empty.png) no-repeat 50% 50%;
  margin-bottom: 18px;
}

// sku-detail
#order-view-list-content,
#order-detail-view-content {
  // 容器
  .order-detail-item {
    position: relative;
    padding: 20px 30px;
    border: 1px solid #f8f8f8;
  }

  // 主要信息
  .order-detail-item-main {
    overflow: hidden;
    position: relative;
    min-height: 80px;
    padding-left: 110px;
  }

  // 产品图片
  .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
  }

  .order-label {
    color: #878787;
  }

  .order-skuid {
    margin-left: 20px;
  }

  .order-detail-item-main li {
    line-height: 28px;
  }

  // 规格
  .order-detail-item-tag {
    @extend %inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 10px;
    background: #fff;
    font-size: 0.9em;
  }
  .order-detail-item-tag-v{
    float: right;
    color: #f28730;
    background: transparent;
    border: 1px solid #f28730;
    padding: 0 10px;
    height: 26px;
    line-height:24px;
    margin: 0 6px
  }
  .order-detail-item-tag-vi{
    float: right;
    color: #f23030;
    background: transparent;
    border: 1px solid #f23030;
    padding: 0 10px;
    height: 26px;
    line-height:24px;
    margin: 0 6px
  }
  // side
  .order-detail-item-side {
    float: right;
  }

  .order-detail-item-info {
    float: right;
    height: 80px;
    line-height: 80px;
  }

  .order-detail-item-action-refund,
  .order-detail-item-action-delete {
    float: right;
    margin-left: 30px;
    height: 80px;
    line-height: 80px;
  }

  .order-detail-item-action-delete {
    cursor: pointer;
    width: 40px;
    background: url(../images/icon-delete.png) no-repeat 50% 50%;
  }

  .order-detail-item-price {
    color: #d064ce;
    font-size: 14px;
  }

  .order-detail-item-count {
    text-align: right;
    margin-top: 40px;
  }
}

.content-body {
  overflow: hidden;
  .content-side {
    float: right;
  }
  .content-main {
    overflow: hidden;
  }
}

// 价格汇总
.order-pay-detail {
  text-align: right;
  margin-left: 10px;
  li {
    line-height: 24px;
  }
}

.user-balance,
.order-price-pay {
  color: #d064ce;
  display: none;
  #order-detail &,
  .order-status-has-user & {
    display: block;
  }
}

// 优惠券
#order-coupon-content {
  width: 46%;
  padding: 29px 30px 21px 30px;
  background: #f8f8f8;
  float: right;
  line-height: 24px;
  box-sizing: border-box;
}
.width-100{
  width: 100% !important;
}

// 操作员
.order-detail-item-operator {
  dt, dd {
    @extend %inline-block;
  }
  select {
    height: 24px;
    width: 180px;
  }
}

// 支付方式
#order-pay-methods {
  height: 120px;
  padding-top: 12px;
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
  background: rgba(255,255,255,0.85);
  width: 80%;
  button {
    @extend %inline-block;
    margin: 0 10px 4px 10px;
    width: 110px;
    padding: 10px 0;
  }
}

// 默认状态 - sku
#order-default-view-content {
  display: block;
  .order-status-has-sku & {
    display: none;
  }
}

#order-detail-view-content {
  display: none;
  .order-status-has-sku & {
    display: block;
  }
}


// 默认状态 - user
#user-default-view-content {
  display: block;
  .order-status-has-user & {
    display: none;
  }
}

#user-detail-view-content {
  width: 48%;
  padding: 20px 30px;
  line-height: 24px;
  display: none;
  .order-status-has-user & {
    display: inline-block;
    overflow: hidden;
  }
}

// 绑定会员且存在优惠券
#order-coupon-content {
  display: none;
  .order-status-has-user-coupon & {
    display: inline-block;
  }
}

// 绑定会员
#order-price-pay {
  display: none;
  .order-status-has-user & {
    display: block;
  }
}

// 和商品绑定
#order-pay-detail,
#order-pay-methods {
  display: none;
  .order-status-has-sku & {
    display: block;
  }
}

// 存在一个状态就显示
#order-operator-content-buma{
  display: none;
  border-radius: 4px;
  width: 200px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 16px;
  position: absolute;
  top:16px;
  background: rgba(220,211,211,0.5);
}
#order-operator-content {
  display: none;
  .order-status-has-user &,
  .order-status-has-sku & {
    display: inline-block;
    position: relative;
    top: -95px;
  }
}

// 会员价格, 需要和会员/订单绑定
#order-price-pay {
  display: none;
  .order-status-has-all {
    display: block;
  }
}

#order-view-content {
  .order-detail-item-action-delete {
    display: none;
  }
}

.order-detail-item-action-refund {
  .order-notsupport &,
  #order-detail-view-content & {
    display: none;
  }
}

#order-view-pay-content {
  border-top: 1px solid #a5a5a5;
  padding: 10px 0;
  overflow: hidden;
  text-align: center;
  dt, dd {
    @extend %inline-block;
  }
  dt {
    padding-left: 10px;
  }
}

#order-price-content,
#order-view-user-content,
#order-view-list-content,
#order-view-pay-content {
  margin-top: 15px;
}
#order-view-price-content{
  margin: 0;
  background: #f8f8f8;
  padding: 20px 30px;
}



#order-remark-view-content{
  width: 184px;
  box-sizing: border-box;
  display: none;
  overflow: hidden;
  margin-bottom: 15px;
  textarea{
    border: 1px solid #f8f8f8;
    border-radius: 3px;
    width: 179px;
    height: 84px;
    margin-top: 5px;
    resize: none;
    color: #333;
    box-sizing: border-box;
  }
  button{
    position: absolute;
    bottom: 15px;
    right: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px 12px;
    background: #fff;
    font-size: 0.9em;
  }
}

#order-remark-view-content-detial{
  width: 100%;
  margin-top: 15px;
  position: relative;
  border: 1px solid #e0e0e0;
  padding: 10px 0 0 25px;
  height: 90px;
  overflow: hidden;
  margin-bottom: 15px;
  box-sizing: border-box;
  textarea{
    width: 100%;
    color: #333;
    padding: 0 !important;
    height: 100px;
    resize: none;
  }
  button{
    position: absolute;
    bottom: 15px;
    right: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px 12px;
    background: #fff;
    font-size: 0.9em;
  }
}
.order-chang-price{
  width: 55px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  height: 20px;
  padding-left: 5px;
}
.order-chang-num{
  padding: 1px;
  float: left;
  width: 30px;
  border: 1px solid #e0e0e0;
  height: 20px;
  text-align: center;
  outline: none;
}
#order-remark-view-content
{
  margin-left: 10px;
  display: none;
  .order-status-has-sku & {
    display: inline-block;
    margin-right: 20px;
  }
}
#air-up{
  margin-right: 30px;
  width: 226px;
  height: 100px;
  background: #f8f8f8;
  float: right;
  padding: 10px 0 10px 18px;
  border-radius: 4px;
  p{
    margin-bottom: 5px;
  }
  .p{
    color: #cc73cf;
  }
 }
.btn-124-80{
  width: 124px;
  height: 80px;
}
#order-price-defined-val{
  color: #cc73cf;
}
.buma-li{
  overflow: hidden;
  display: inline-block;
  max-width: 14em;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333 !important;
}
.buma-il{
  background: rgba(255,255,255,0.9);
  margin-bottom: 2px;
  padding: 12px 16px 12px 16px!important;
}
.m-0{
  margin: 0 !important;
  display: none;
}
#order-good-box{
  box-sizing: border-box;
  width: 110px;
  height: 136px;
}
#order-getcate-content{
  display: none;
  max-height: 70vh;
  overflow-y: scroll;
  float:left;
  width: 11%;
  margin-right: 20px;
  li{
    border-radius:4px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    width: 100%;
    background: rgba(255,255,255,0.6);
    color: #000;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  li:hover{
    color: #333 !important;
    background: rgba(255,255,255,0.9) !important;
  }
}
.activeLi{
  color: #333 !important;
  background: rgba(255,255,255,0.9) !important;
}
#order-detail-view-content{
  overflow-x: hidden;
}

#order-detail-view-content::-webkit-scrollbar {
  width:0;
}
#order-getcate-content::-webkit-scrollbar {
  width:0;
}
#order-goods-content::-webkit-scrollbar {
  width:0;
}
#order-goods-content{
  margin-left: 12vw;
  width: 61vw;
  display: none;
  padding: 1vw 0 1vw 1vw;
  background: rgba(255,255,255,0.9) url(../images/shopgoodsbacks.png) no-repeat 50% 50%;
  border-radius: 4px;
  height: 70vh;
  margin-bottom: 130px;
  box-sizing: border-box;
}
#order-goods-contents::-webkit-scrollbar {
  width:0;
}
#order-goods-contents{
  width: 61vw;
  display: none;
  padding: 1vw 0 1vw 1vw;
  box-sizing: border-box;
  background: rgba(255,255,255,0.9);
  border-radius: 4px;
  height: 70vh;
  overflow-y: scroll;
  margin-bottom: 130px;
}
.cut-number-order{
  float: left;
  cursor: pointer;
  height: 24px;
  border: 1px solid #e0e0e0;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 19px;
  background: #f8f8f8;
}
.add-number-order{
  float: left;
  cursor: pointer;;
  height: 24px;
  border: 1px solid #e0e0e0;
  width: 21px;
  background: #f8f8f8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.Right{
  float: right;
}

.cardlosted{
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 2px;
}
.cardlose{
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 2px;
}
.cardlock{
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 2px;
}

.ctrl-sidebar{
  display: none;
  position: fixed;
  left:0;
  top: 0;
  z-index: 10000;
}

//密码浮层
.password-box{
  border:1px solid #ebebeb;
  z-index: 10;
  background: #fff;
  display: none;
  position: fixed;
  padding: 10px;
  border-radius: 4px;
  top: 200px;
  left: 50%;
  margin-left: -222px;
  p{
    font-size: 18px;
    margin-left: 20px;
    margin-top: 15px;
  }
  button:first-of-type{
    margin-top: 20px;
    margin-left: 274px;
    margin-bottom: 20px;
    background: #fff;
  }
  button:last-of-type{
    margin-left: 15px;
    background: #e0e0e0;

  }
}

.order-table{
  border-radius: 4px;
  th{
    background: #e0e0e0 !important;
    color: #000;
    font-size: 14px;
  }
  td{
    font-size: 12px;
    height: 50px;
    line-height: 48px;
    text-align: center;
    border: 1px solid #e0e0e0;
  }
  .table-tr{
    font-size:14px;
  }
  .table-head{
    color: #e0e0e0 !important;
  }
  .order-thead{
    border: 1px solid #e0e0e0;
  }
}
#order-view-user-content{
  dl{
    margin-top: 10px !important;
  }
}


//商家扫码浮层
#sacn-form-content{
  position: fixed;
  top: 20%;
  left: 50%;
  margin-left: -220px;
  padding: 20px 65px 30px 20px;
  background: #fff;
  border-radius: 4px;
  border:1px solid #ccc;
  z-index: 10;
}
.scan-input-pay{
  padding-left: 10px;
  height: 25px;
  border-radius: 3px;
  border:1px solid #e0e0e0;
  cursor: pointer;
}
#quit-pay-scan{
  cursor: pointer;
  margin-left: 20px;
}


#pay-method-limite1{
  background: darkgray !important;
}
#pay-method-limite2{
  background: darkgray !important;
}


.zz-fc{
  width: 100%;
  height: 100vh;
  background: rgba(255,255,255,0.5);
  position: fixed;
  z-index: 9;
  display: none;
}
.cu_div{
  margin-top: 7px;
  float: right;
  width: 22px;
  height: 22px;
  background: #ff7800;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 12px;
}
.te_div{
  margin-top: 7px;
  float: right;
  width: 22px;
  height: 22px;
  background: #e4393c;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 12px;
}
.success_fc{
  position: absolute;
  z-index: 10;
  background: #fff;
  border: 1px solid #e0e0e0;
  left: 50%;
  width: 430px;
  margin-left: -215px;
  top: 30%;
  border-radius: 4px;
  padding: 30px;
}
.success_close{
  float: right;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
}
.user-form_list{
  display: none;
  position: absolute;
  z-index: 999;
  left: 416px;
  top: 114px;
  border: 1px solid #878787;
  li{
    width: 200px;
    padding-left: 10px;
    height: 25px;
    line-height: 25px;
  }
  li:hover{
    background: #e0e0e0;
  }
}
.user-detail-privige{
  cursor: pointer;
  min-width: 200px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background: rgba(255,255,255,0.9);
  margin: 0 5px 5px 0;
  display: inline-block;
  padding: 10px 12px;
  p{
    max-width: 15em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.user-detail-coupon{
  cursor: pointer;
  min-width: 200px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background: rgba(255,255,255,0.9);
  margin: 0 5px 5px 0;
  display: inline-block;
  padding: 10px 12px;
  p{
    max-width: 15em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.user-detail-privige_active{
  border: 1px solid #ff6969;
}
.list-apply{
  min-height: 500px;
  .shop-arrow{
    display: none;
  }
  th{
    position: relative;
  }
  th ul{
    z-index: 1;
    list-style: none;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
    top:42px;
    display: none;
    font-size: 14px;
    font-weight: 400;
    background: white;
  }
  ul li:hover{
    background: #cc73cf;
    color: white
  }
  .active ul{
    display: block;
  }
  .active ul li{
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}

//订单统计
#order-count{
    //列表
   .table{
     min-height:500px
   }
   .table table{
     width: 100%;
     text-align: center;
     table-layout:fixed;
   }
   .table tr:nth-child(2n){
     background: #f8f8f8;
   }
   .table tr:hover{
     background: #e6e6e6
   }
   .table tr{
     font-size: 12px;
   }
   .table tr td{
     padding: 14px 5px;
     vertical-align: middle;
     line-height:1.5;
     word-break:break-all;
     text-align: center;
   }
   .table thead{
     height: 42px;
   }
   .table th{
     height: 42px;
     line-height: 42px;
     color: #505050;
     font-size: 12px;
     font-weight: 700;
     background: #ebebeb;
     text-align: center;
     position: relative;
   }
   .table th ul{
     z-index: 1;
     list-style: none;
     width: 100%;
     text-align: center;
     box-shadow: 0 0 10px #bcbcbc;
     padding: 0;
     position: absolute;
     display: none;
     font-size: 14px;
     font-weight: 400;
     background: white;
   }
   .the ul li:hover{
     background: #cc73cf;
     color: white
   }
   .hover ul li:hover{
     background: #cc73cf;
     color: white
   }
   .table .active ul{
     display: block
   }
   .table .active ul li{
     font-size: 12px;
   }


  td a{
    color: #A52F9C; 
  }
  .countdetail{
    z-index:999;
    position:fixed;
    left:50%;
    top:80px;
    margin-left:-540px;
    width:1080px;
    height:600px;
    background:#fff;
    padding:20px;
    display:none;
    .close{
      position:absolute;
      right:20px;
      top:20px;
      border-radius:50%;
      border:1px solid #000;
      width: 50px;
      height: 50px;
      line-height: 48px;
      text-align: center;
    }
    .count-detail-title{
      margin-top:20px;
      color:#999;
    }
    .detail-count{
      .sum{
        width:200px;
        margin-top:20px;
        float:left;
        p{
          color:#74A3E3;
          font-size:26px;
          text-align:right;
          span{
            font-size:16px;
          }
        }
      }

    }
  }
  .count-box{
    overflow:hidden;
    width:100%;
    .sum-box{
      width:140px;
      margin-left:20px;
      margin-top:20px;
      color: #505050;
      float:left;
      .title{
        font-size:16px;
        line-height:30px;
      }
      .sum{
        line-height:105px;
        font-size:18px;
        text-indent:20px;
      }
    }
    p{
      width: 100%;
      float: left;
      line-height:30px;
      a{
        color: #cc73cf;
        float: right;
        margin-right: 30px;
      }
    }
  }
  .sales-date-choose{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-date-choose-active{
    background: #cc73cf;
    border:1px solid #cc73cf;
    color: #fff;
  }
  .sales-shop-choose-active{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
    background: #cc73cf;
    border:1px solid #cc73cf;
    color: #fff;
  }
  .sales-shop-choose-sel-active{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
    background: #cc73cf;
    border:1px solid #cc73cf;
    color: #fff;
  }
  .sales-shop-choose{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-shop-choose-sel{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-shop-choose-look{
    display: none;
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-count-certain{
    float: right;
  }
  .sales-up-img{
    position: relative;
    top: 7px;
    margin: 0 5px 0;
    width:20px;
    height:20px;
    background:url('../images/noselect.jpg');
    cursor: pointer;
  }
  .img-choose{
    .sales-up-img{
      background:url('../images/selected.jpg');
    }
  }
  .sales-img-text{
    margin-right: 20px;
    position: relative;
    top:2px;
  }
  .img-hidden{
    display: none;
  }
  .ranking-spu,
  .ranking-sales{
    cursor: pointer;
    img{
      position: relative;
      top:4px ;
      left:8px;
    }
  }
  .shop-number-button {
      font-size: 14px;
      margin-left: 20px;
      color: black;
      background: white;
      border: 1px dashed #cccccc;
      border-radius: 3px;
      height: 27px;
      outline: none;
      padding: 0 10px;
      text-align: center;
  }
  .add-shop-list {
    color: #cc73cf;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    line-height: 28px;
    padding: 0 10px;
    outline: 0;
    text-align: center;
  }

}


/* 商品选择，店铺列表 */
 .activity-city-th, .activity-sname-th, .activity-stype-th, .activity-snum-th {
  width: 25%;
  height: 42px;
  line-height: 42px;
  color: #505050;
  font-size: 14px;
  font-weight: 700;
  background: #ebebeb;
  text-align: center;
  position: relative
}

 #activity-form-shop {
  width: 100%;
  min-height: 480px
}

 #activity-form-shop table {
  width: 100%
}

 .activity-tbody-td {
  width: 25%;
  height: 42px;
  line-height: 42px;
  font-size: 12px;
  text-align: center;
  position: relative
}

 .activity-city-zd img {
  position: absolute;
  top: 6px;
  left: 5px
}

 .activity-city-all img {
  position: absolute;
  top: 6px;
  left: 5px
}

 .activity-tbody-td img {
  position: absolute;
  top: 16px;
  left: 10px
}

 .activity-shop-number, .activity-good-number {
  width: 286.5px;
  height: 50px;
  line-height: 50px;
  background: transparent;
  text-align: center;
  position: relative
}

 .activity-shop-number img, .activity-good-number img {
  position: absolute;
  top: 16px;
  left: 10px
}

 .add-shop-num, .add-good-num {
  color: #cc73cf;
  margin-left: 20px;
  background: white;
  border: 1px solid #cc73cf;
  border-radius: 3px;
  height: 28px;
  width: 56px;
  margin-top: 12px;
}

 .activity-shai {
  width: 40%;
  margin-left: 30%;
  text-align: center
}

 .snake ul {
  display: none
}

 .skit ul {
  display: block
}

 #activity-form-good {
  width: 100%;
  min-height: 450px
}

 #activity-form-good table {
  width: 100%
}

 .good-tr td {
  text-align: center;
  font-size: 12px
}

 .good-tr>td:nth-child(1), .good-tr>th:nth-child(1) {
  width: 10%
}

 .good-tr>td:nth-child(2), .good-tr>td:nth-child(4), .good-tr>td:nth-child(5), .good-tr>th:nth-child(2), .good-tr>th:nth-child(4), .good-tr>th:nth-child(5) {
  width: 21%
}

 .good-tr>td:nth-child(3), .good-tr>th:nth-child(3) {
  width: 27%
}

 .good-td {
  position: relative
}

 .Good-img {
  position: absolute;
  left: 10px;
  top: 15px
}

 .good-up-img {
  position: relative;
  top: 5px;
  left: -20px
}

 .form-group>label {
  font-size: 12px;
  line-height: 30px
}

 #radio-img {
  margin-left: 12.5px
}

 .del-fuceng {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 1400px;
  background: rgba(0,0,0,0.4);
  z-index: 1
}

 th.thp form {
  position: absolute;
  top: 42px;
  left: 0;
  display: none;
  width: 300px;
  height: 108px;
  background-color: #fff;
  box-shadow: 0 0 10px #bcbcbc
}

 th.thp input {
  margin: 14px 0;
  font-size: 13px;
  color: #333
}

 th.thp .lso-time-start, th.thp .lso-time-end {
  width: 120px;
  height: 30px;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #e8e8e8
}

 th.thp .lso-time-start {
  margin-right: 16px
}

 th.thp .form-p {
  text-align: right;
  padding-right: 17px
}

 th.thp .lso-time-cancel, th.thp .lso-time-confirm {
  border: none;
  background-color: #fff;
  cursor: pointer
}

//商品列表
.good-th{
  font-weight: 700;
  color: #505050;
  background-color: #ebebeb;
  vertical-align: middle;
  font-size: 12px;
  line-height: 1.5;
  border: none;
  text-align: center;
}
#activity-good-tbody tr:nth-child(even){
  background: #f8f8f8;
}
#activity-good-tbody tr:hover{
  background: #e6e6e6;
}
.good-tr{
  height: 42px;
  line-height: 42px;
  text-align: center;
}

 #list-activity-sku-content a {
  color: #cc73cf;
  border: 0;
  text-decoration: none;
  cursor: pointer
}

 .sku-tr>th {
  width: 9%
}

 .th ul {
  margin: 0;
  list-style: none;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 10px #bcbcbc;
  padding: 0;
  position: absolute
}

 .good-tr ul, #activity-form-shop table ul {
  z-index: 1;
  margin-left: 30%;
  list-style: none;
  width: 40%;
  text-align: center;
  box-shadow: 0 0 10px #bcbcbc;
  padding: 0;
  position: absolute
}

 .th ul li {
  font-size: 12px;
  font-weight: 400;
  height: 50px;
  line-height: 48px;
  border: 1px solid #ebebeb;
  border-top: 0;
  background: white;
  color: #666666
}

 .good-tr ul li, #activity-form-shop table ul li {
  position: relative;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  line-height: 28px;
  border: 1px solid #ebebeb;
  border-top: 0;
  background: white;
  color: #666666
}

 #activity-form-shop table ul li:hover, .good-tr ul li:hover, .th ul li:hover {
  background: #cc73cf;
  color: white
}

 ul li div p {
  height: 100%
}


.package-goods-on{
  position: relative;
}
.packages-detail-box-p{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 15px;
  color: #333;
  padding-left: 15px;
  line-height: 50px;
  background: #ebebeb;
}
.packages-detail-box-p span{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.package-goods-on-hover{
  .packages-detail-box-order-center{
    display: block;
  }
}
.packages-detail-box-order-center{
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  display: none;
  position: fixed;
  left: 50%;
  margin-left: -100px;
  top:100px;
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 1;
}
.packages-detail-box-order{
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  display: none;
  position: fixed;
  top: 80px;
  width: 332px;
  left: 50%;
  margin-left: -166px;
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 1;
}
.packages-detail::-webkit-scrollbar {
  width:0;
}.packages-detail{
  background: #fff;
  padding: 15px 15px 1px 15px;
  max-height: 350px;
  overflow-y: scroll;
}
.packages-detail div.packages-detail-box-div:nth-child(odd){
  width: 270px;
  margin-bottom: 14px;
  height: 32px;
  border: 1px solid #ebebeb;
  padding: 15px;
  border-radius: 3px;
}
.packages-detail div.packages-detail-box-div:nth-child(even){
  width: 270px;
  margin-bottom: 14px;
  height: 32px;
  border: 1px solid #ebebeb;
  padding: 15px;
  border-radius: 3px;
}
.packages-detail-img{
  float: left!important;
  width: 32px!important;
  height: 32px!important;
  margin: 0!important;
}
.packages-detail-box{
  float: right;
  width: 230px;
  height: 32px;
}
.packages-detail-div{
  width: 160px;
  height: 16px;
  color: #333;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 13px;
  display: inline-block;
}
.packages-detail-span{
  display: block;
  float: right;
}
.packages-detail-p{
  width: 230px;
  height: 16px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
}
#packages-user-content{
  border:1px solid #ebebeb;
  width: 300px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: fixed;
  z-index: 10;
  left:50%;
  margin-left: -150px;
  top:150px;
  display: none;
}
.package-user-quit{
  margin-top: 20px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-size: 14px;
  height: 28px;
  padding: 0 10px;
  float: right;
}
.package-user-certain{
  margin-top: 20px;
  cursor: pointer;
  margin-left: 20px;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-size: 14px;
  height: 28px;
  padding: 0 10px;
  float: right;
}
.packages-detail-close{
  float: right;
  position: relative;
  left: 6px;
  bottom: 6px;
}
.My_coupon{
  display: none;
  font: inherit;
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 20px;
  border: 1px solid #878787;
  border-radius: 4px;
  color: #666;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
  cursor: pointer;
  margin-left: 15px;
}
.My_privige{
  display: none;
  font: inherit;
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 20px;
  border: 1px solid #878787;
  border-radius: 4px;
  color: #666;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
  cursor: pointer;
}
.DiscountDescription-span{
  background: #fff;
  border: 1px solid #ff6969;
  display: inline-block;
  padding: 3px 10px;
  border-radius: 6px;
  color: #ff6969;
  font-size: 12px;
  margin-right: 10px;
  max-width: 15em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  float: left;
}
.DiscountDescription-span span{
  font-size: 14px;
  display: block;
  text-align: center;
}
.brief-loading{
  height: 75px;
  background-repeat: no-repeat!important;
  background-position: 50% 50%!important;
  background-image: url(../images/requesting.gif)!important;
}