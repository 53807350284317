.size,
.list-task,
.list-size,
.list-order,
.list-apply,
.list-rule,
.list-shop,
.list-review,
.list-member,
.list-revenue,
.list-privilege,
.list-memberex,
.list-refund,
.list-goods-sku,
.list-goods-spu,
.list-coupon,
.list-keliu,
.list-evaluate-good,
.list-evaluate-serve,
.list-appoint-main,
.list-sales-count,
.list-appoint-sub,
.list-appoint-list,
.list-sales-shop,
.list-common,
.list-package,
.list-yunying {

  table,
  .table {
    width: 100%;
    table-layout: fixed;
  }

  thead th,
  .thead {
    font-weight: 700;
    color: $title-color;
    background-color: #ebebeb;
  }

  tr {
    height: 42px;
  }

  tr,
  .tr {
    line-height: 42px;
  }

  .tr {
    font-size: 0;

    .td,
    .th {
      @extend %inline-block;
      vertical-align: middle;
    }
  }

  td,
  .td,
  th,
  .th {
    vertical-align: middle;
    font-size: 12px;
    line-height: 1.5;
    border: none;
    text-align: center;
    overflow-wrap: break-word;
  }

  tbody tr:nth-child(even),
  .tbody .tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tbody tr,
  .tbody .tr {
    &:hover {
      background-color: rgb(230, 230, 230);
    }
  }
}

.list-task {
  thead tr {
    height: 42px;
    line-height: 42px;
  }

  tbody tr,
  .tbody .tr {
    height: auto;
    line-height: 24px;
    padding: 15px 0;
  }

  pre {
    white-space: pre-wrap;
  }
}

// 身材信息
%size {
  cursor: default;
  text-align: center;
}

.member-size {
  @extend %none;
  @extend %size;
}

.size-action {
  text-align: left;
  margin: 15px auto;
}

// 表格样式
.size {
  &-hover-col {
    background-color: #ebebeb;
  }

  &-hover-cell {
    color: #fff;
    background-color: #e3a8ea;
  }

  &-selected {
    color: #fff;
    background-color: #333;
  }

  &-save {
    text-align: center;
    padding: 15px;

    a {
      @extend %inline-block;
      padding: 10px 25px;
      border-radius: 4px;
      color: #fff;
      background-color: #cc73cf;
      font-size: 12px;

      &:hover {
        color: #fff;
        background-color: #86339a;
      }
    }
  }

  &-input-define {
    padding: 13px auto;
  }

  &-input {
    width: 80%;
    margin: 0 auto;
    border: 1px solid #eee;
    border-radius: 4px;
    overflow: hidden;
  }

  &-input input {
    box-sizing: border-box;
    width: 100%;
    border: 0;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
}

// 身材信息
.size,
.list-size {
  $list: (date: 12,
      bb: 10,
      lbp: 10,
      rbp: 10,
      upper-chest: 10,
      lower-chest: 10,
      stomach: 10,
      waist: 10,
      abdomen: 10,
      hips: 10,
      lleg: 10,
      hph: 10,
      rleg: 10,
      action: 7);
  $all: 0;

  @each $a,
  $w in $list {
    $all: $all + $w;
  }

  @each $classname,
  $width in $list {
    .size-item-#{$classname} {
      $item-width: $width/$all * 100%;
      width: $item-width;
    }
  }
}

// 会员规则
.list-rule {
  $list: (title: 20%,
      level: 60%,
      discount: 20%);

  @each $classname,
  $width in $list {
    .rule-item-#{$classname} {
      width: $width;
    }
  }
}

// 任务流程
.list-task {
  tbody {
    font-size: 12px;
  }

  .task-item-datetime,
  .task-item-description {
    text-align: left;

    &-inner {
      padding-left: 30px;
    }
  }

  .task-item-datetime {
    width: 20%;
  }

  .task-item-description {
    width: 80%;
  }
}

// 库存详情
.page-detail {
  padding: 16px 20px;
  background-color: #f8f8f8;
  font-size: 12px;

  &-label {
    line-height: 36px;
    text-align: right;
  }

  &-value {
    padding-left: 10px;
  }

  &-qrcode {
    margin-top: 10px;

    div {
      display: inline-block;
      padding: 20px 0;
      width: 260px;
      // height: 200px;
      text-align: center;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
    }

    p {
      // margin-top: 10px;
      line-height: 18px;
    }

    img {
      margin-bottom: 10px;
      width: 180px;
      height: 180px;
    }

    a {
      display: block;
      margin-top: 10px;
      width: 260px;
      line-height: 30px;
      color: #cc73cf;
      font-size: 14px;
      text-align: center;
    }
  }

  button {
    margin-top: 30px;
  }

  #page-goods & {
    margin-bottom: 15px;
  }
}

.list-order {
  .order-item-action a {
    color: #A52F9C;

    &.btn-order-cancel {
      cursor: default;
      color: rgb(135, 135, 135);
    }
  }
}

.member-has-size {
  color: #cc73cf;
  cursor: pointer;
}

$pages: (refund: (prefix: refund-item,
    fixed: true,
    cols: (time: 15%,
      no: 15%,
      name: 20%,
      vector: 20%,
      price: 10%,
      worker: 20%,
      action: 0)),
  goods-sku: (prefix: goods-sku-item,
    fixed: true,
    cols: (no: 15%,
      name: 20%,
      vector: 30%,
      price: 15%,
      count: 10%,
      action: 10%)),
  goods-spu: (prefix: goods-spu-item,
    fixed: true,
    cols: (date: 20%,
      no: 15%,
      name: 25%,
      category: 30%,
      action: 10%)),
  order: (prefix: order-item,
    fixed: true,
    cols: (branch: 15%,
      time: 15%,
      id: 15%,
      price: 10%,
      q: 10%,
      member: 10%,
      assistant: 9%,
      status: 8%,
      action: 10%)),
  shop: (prefix: shop-item,
    fixed: true,
    cols: (city: 6%, // 城市
      name: 12%, // 店铺名称
      store:6%, //门店类型
      type: 6%, // 店铺类型
      time:10%, //入驻时间
      telephone: 10%, // 电话
      address: 33%, // 详细地址
      action: 8% // 操作
    )),
  review:(prefix: review-item,
    fixed: true,
    cols: (submitter: 6%, //提交人姓名
      phoneNumber: 10%, //手机号码
      shopName:12%, //店铺名称
      shopClassify: 10%, // 店铺分类
      operationType:6%, //店铺类型
      branchType: 6%, //运营类型
      address: 20%, // 详细地址
      img:13%,
      action: 17% // 操作
    )),
  coupon: (prefix: coupon-item,
    fixed: true,
    cols: (description: 20%,
      type: 10%,
      channel: 20%,
      more: 10%,
      date: 20%,
      range: 10%,
      action: 10%)),
  yunying: (prefix: yunying-item,
    fixed: true,
    cols: (time: 20%,
      type: 20%,
      detail: 50%,
      action: 10%)));

@each $page,
$config in $pages {
  $prefix: map-get($config, prefix);
  $list: map-get($config, cols);
  $length: length($list) - 1;
  $aw: map-get($list, 'action');
  $fixed: map-get($config, fixed);

  @each $classname,
  $width in $list {
    .#{$prefix}-#{$classname} {
      width: $width;

      @if $fixed {
        .no-all-action & {
          width: $width + $aw/$length;

          @if $classname==action {
            width: 0;
            height: 0;
            overflow: hidden;
          }
        }
      }
    }
  }
}

$search: (list-goods-sku-search: (prefix: goods-sku-item,
    cols: (branch: 15%,
      no: 15%,
      name: 20%,
      vector: 15%,
      price: 15%,
      count: 10%,
      action: 10%)));

@each $_search,
$config in $search {
  $prefix: map-get($config, prefix);
  $list: map-get($config, cols);
  $length: length($list) - 1;
  $aw: map-get($list, 'action');

  @each $classname,
  $width in $list {
    .#{$_search} .#{$prefix}-#{$classname} {
      width: $width;

      .no-all-action & {
        width: $width + $aw/$length;

        @if $classname==action {
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }
    }
  }
}

.goods-sku-item-branch {
  display: none !important;

  .list-goods-sku-search & {
    display: table-cell !important;
  }
}

.disabled-action {
  display: none !important;
}

.disabled-all-actions {

  #employees .actions,
  #btn-employee-add {
    display: none !important;
  }
}

.list-status-action {
  cursor: pointer;
  color: #cc73cf;
  margin: 0 4px;
}

.select-box {
  label {
    color: #666;
  }

  position: relative;
  margin-bottom: 10px;
  padding: 16px 20px 20px;
  background-color: #f8f8f8;

  .control-value {
    width: auto;
    margin-top: -2px;
  }

  .search {
    position: absolute;
    bottom: 16px;
    right: 60px;
  }

  .inviter {
    position: absolute;
    bottom: 20px;
    right: 290px;
  }
}