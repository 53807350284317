
button.edit_rule {
  display: none;
}
.tophdead_one {
  width:100%;
  height: 50px;
  background-color: #ebebeb;
  margin-bottom:20px;
  padding:15px 0;
  p {
    margin-left:15px;
    color: #a8a8a8;
  }
}
.thead {
  width: 100%;
  .tr {
    width: 100%;
    height: 42px;
    background-color: #ebebeb;
    line-height: 42px;
    >div {
      display: inline-block;
    }
    .rule-item-title {
      width: 20%;
      text-align: center;
    }
    .rule-item-level {
      width: 59%;
      text-align: center;
    }
    .rule-item-discount {
      width: 20%;
      text-align: center;
    }
  }
}
.tbody {
  width: 100%;
  .tr {
    height: 42px;
    line-height: 42px;
    width: 100%;
    >div {
      display: inline-block;
    }
    .rule-item-title {
      width: 20%;
      text-align: center;
    }
    .rule-item-level {
      width: 59%;
      text-align: center;
    }
    .rule-item-discount {
      width: 20%;
      text-align: center;
    }
  }
}
.top_title {
  width: 100%;
  height: 49px;
  line-height:49px;
  background-color: #ebebeb;
  margin-bottom:20px;
  p {
    margin-left:20px;
  }
}
.chongzhi_num {
  position: relative;
  padding:20px;
  min-height: 200px;
  background-color: #ebebeb;
  p {
    margin-left:20px;
    margin-bottom:15px;
    span {
      color: #a8a8a8;
      cursor: pointer;
    }
  }
  img {
    width:160px;
    position: absolute;
    right:110px;
    top:0px;

  }
    ul {
      li {
        float: left;
        width: 66px;
        height: 31px;
        position: relative;
        line-height: 30px;
        text-align: center;
        margin-left:20px;
        margin-bottom:15px;
        border-radius: 5px;
        input {
          width: 65px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          padding:0;
          border:0;
          border-radius: 5px;
          outline:none;
          background-color: #fff;
        }
        img {
          width:15px;
          position: absolute;
          right:-12px;
          top:-8px;
          border:1px solid #ddd;
          background-color: #fff;
         border-radius: 8px;
          cursor: pointer;
        }
      }
      li.adds {
        cursor: pointer;
        background-color: #fff;
      }
    }
  .coupon-send {
    input {

    }
  }

  span.btns {
    display: block;
    width: 70px;
    height: 35px;
    cursor: pointer;
    text-align: center;
    line-height:35px;
    color: #fff;;
    background-color: #cc73cf;
    border-radius: 6px;
  }
}

ul.member-rule-table {
  li.thead {
    >div {
      display: inline-block;
      height: 50px;
    }
    .member-rule-title {
      width:15%;
    }
    .member-rule-level {
      width: 55%;
    }
    .member-rule-discount {
      width: 15%;
    }
  }
  li.bodyli {
    .member-rule-title {
      width:15%;
    }
    .member-rule-level {
      width: 55%;
    }
    .member-rule-discount {
      width: 15%;
    }
    .member-rule-action {
      width:2%;
    }
  }
}
.configure-content{
    display: flex;
    align-items: center;
}
.configure-content label{
  margin-right: 10px;
}
#exchange-name{
    height: 38px;
    line-height: 1.3;
    line-height: 38px\9;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-radius: 2px;
    border-color: #e6e6e6;
    padding-left: 10px;
}
#exchange-rule{
  border: 1px solid #e6e6e6;
}
.save-configure,.confirm-btn-search,.clear-btn-search,.confirm-btn-search1,.clear-btn-search1{
  display: block;
  width: 70px;
  height: 35px;
  cursor: pointer;
  text-align: center;
  line-height: 35px;
  color: #fff;
  background-color: #cc73cf;
  border-radius: 6px;
  margin-top: 20px;
  margin-left: 20px;
}
