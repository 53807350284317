#category {
  font-size: 0;
  overflow: hidden;
  min-height: 240px;
  border: 1px solid #ddd;
  .cx {
    font-size: 12px;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: 33.33333%;
    padding-bottom: 1000em;
    margin-bottom: -1000em;
    box-sizing: border-box;
    border-right: 1px solid #ddd;
  }
  .cx:last-child {
    border-right: 0 none;
  }

  h1 {
    height: 42px;
    line-height: 42px;
    text-align: center;
    background: #ebebeb;
    padding: 0 10px;
  }
  form {
    margin: 4px 10px;
  }

  .name {
    width: 100%;
    padding: 6px 10px;
    box-sizing: border-box;
  }

  li {
    position: relative;
    overflow: hidden;
    height: 34px;
    line-height: 34px;
    text-align: left;
    padding: 0 20px;
    white-space: nowrap;
    cursor: default;
    &:nth-child(2n + 1) {
      background-color: #f8f8f8;
    }
    &:hover {
      background-color: #ebebeb;
    }
    &.selected {
      &, &:hover {
        color: #fff;
        background-color: #000;
      }
    }
    &:hover, &.selected {
      .del-category-item {
        color: #999;
      }
    }
    &.selected:hover {
      .del-category-item {
        color: #efefef;
      }
    }
    &.selected:hover {
      .edit-category-item {
        color: #efefef;
      }
    }
  }

  .del-category-item {
    position: absolute;
    right: 10px;
    padding: 0 4px;
    color: #efefef;
    cursor: pointer;
  }

  .edit-category-item{
    float: right;
    margin-right: 15px;
    color: #999;
    :hover{
      color: #505050;
    }
  }

}


.edit{
  width:100%;
  height:100%;
  position: fixed;
  left: 0;
  top:0;
  background: rgba(0,0,0,0.3);
  z-index: 10;
  display: none;
}
.edit-fc{
  position: fixed;
  border: 1px solid #333;
  padding: 20px 45px 20px 20px;
  border-radius: 4px;
  background: #fff;
  width: 320px;
  left: 50%;
  margin-left: -160px;
  top: 30%;
  z-index: 11;
  display: none;
  .control-value{
    width:200px;
  }
  .edit-cate-button{
    margin-top: 20px;
    border-radius: 4px;
    border:1px solid #999;
    height: 26px;
    line-height: 24px;
    box-sizing: border-box;
    padding: 0 10px;
    outline: none;
    cursor: pointer;
  }
  .edit-cate-quit{
    cursor: pointer;
    margin-left: 200px;
    margin-right: 10px;
    background: #fff;
  }
}
