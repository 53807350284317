#attribute {
  table {
    width: 100%;
    border-collapse: collapse;
    line-height: 1.5;
    table-layout: fixed;
  }
  tr:nth-child(2n + 1) {
    background-color: #f8f8f8;
  }
  td, th {
    border: 1px solid #ddd;
    vertical-align: middle;
  }
  th {
    background-color: #ebebeb;
    color: #505050;
    height: 42px;
  }
  td {
    padding: 8px 10px;
  }
  .tag-action,
  .tag-category,
  .tag-name {
    text-align: center;
  }
  .tag-no-content {
    color: #ddd;
    .tag-value {
      margin: 4px 5px;
    }
  }
  .tag-have-content .tag-value {
    padding: 0 10px;
    display: inline-block;
    margin: 4px 5px;
    border-radius: 4px;
  }
}
#form-package-content,
#form-edit-content,
#page-goods {
  .spu-values {
    overflow: hidden;
  }
  .tags-item {
    padding: 10px 30px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    margin-bottom: 15px;
    position: relative;
    li {
      overflow: hidden;
      margin: 10px 0;
      line-height: 28px;
    }
    label {
      float: left;
      margin-right: 10px;
    }
    input {
      padding: 6px 4px;
    }
    .control-attr-value {
      width: 280px;
    }
  }
  #form-package-content,
  #form-edit-content,
  #spu-form {
    .tags-item {
      background-color: #fff;
    }
  }
  .tags-item-form {
    .tags {
      padding-top: 20px;
    }
  }
  .tags {
    overflow: hidden;
    margin-bottom: -10px;
  }
  .tag-item {
    position: relative;
    float: left;
    min-width: 36px;
    padding: 0 10px;
    text-align: center;
    margin: 0 10px 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: default;
    -webkit-user-select: none;
  }
  .tags-item-form .tag-item:hover {
    color: #fff;
    background-color: #cc73cf;
    .del-tag {
      display: block;
    }
  }
  .del-tag {
    display: none;
    cursor: pointer;
    position: absolute;
    right: -9px;
    top: -9px;
    border-radius: 50%;
    border: 1px solid #ddd;
    text-align: center;
    line-height: 16px;
    height: 18px;
    width: 18px;
    color: #cc73cf;
    background-color: #fff;
  }
  .tag-tips {
    color: #999;
    margin-left: 10px;
  }
  .actions {
    position: absolute;
    right: 30px;
    top: 25px;
    a {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}


