#goods-sales-list{
  .sales-date-choose{
    line-height: 28px;
    text-align: center;
    width: 60px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-date-choose-active{
    background: #cc73cf;
    border:1px solid #cc73cf;
    color: #fff;
  }
  .sales-shop-choose-active{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
    background: #cc73cf;
    border:1px solid #cc73cf;
    color: #fff;
  }
  .sales-shop-choose-sel-active{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
    background: #cc73cf;
    border:1px solid #cc73cf;
    color: #fff;
  }
  .sales-shop-choose{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-shop-choose-sel{
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    display: inline-block;margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-shop-choose-look{
    display: none;
    line-height: 28px;
    text-align: center;
    padding: 0 10px;
    background: #fff;
    border:1px solid #ebebeb;
    color: #333;
    margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .sales-count-certain{
    float: right;
  }
  .sales-up-img{
    position: relative;
    top: 7px;
    margin: 0 5px 0;
    cursor: pointer;
  }
  .sales-img-text{
    margin-right: 20px;
    position: relative;
    top:2px;
  }
  .img-hidden{
    display: none;
  }
  .ranking-spu,
  .ranking-sales{
    img{
      position: relative;
      top:4px ;
      left:8px;
    }
  }
}
.div-shops{
  .list-sales-shop{
    min-height: 400px;
  }
  .shops-shai{
    width: 40%;
    margin-left: 30%;
    text-align: center;
  }
  .the ul{
    display: none;
    max-height: 300px;
    overflow-y: auto;
  }
  th{
    position: relative;
    width: 35%;
  }
  td {
    position: relative;
    img{
      position: absolute;
      left:10px;
    }
  }
  th ul{
    z-index: 1;
    list-style: none;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
    top:42px;
    display: none;
    font-size: 14px;
    font-weight: 400;
    background: white;
  }
  th ul li:hover{
    background: #cc73cf;
    color: white
  }
  .activeU ul{
    display: block;
  }
  .activeU ul li{
    font-size: 12px;
    height: 30px;
    line-height: 28px;
    border-bottom: 1px solid #ebebeb;
  }
  .isLook{
    display: none;
  }
}
#city-list-content{
  li{
    position: relative;
    img{
      position: absolute;
      top:6px;
      left:6px;
    }
  }
}
.shops-number{
  margin-top: 5px;
  img{
    float: left;
    margin: 4px 10px;
  }
}
.looks-shops-dashed{
  border:1px dashed;
  color: #cc73cf;
}
