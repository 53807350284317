.arrow-down-default {
  display: inline-block;
  width: 5px;
  height: 8px;
  padding: 0 6px;
  background: transparent url(../images/arrowDowndefault.png) no-repeat 5px center;
}
#context .loading{
  height: 190px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(../images/requesting.gif);
}
.arrow-up-default {
  display: inline-block;
  width: 5px;
  height: 8px;
  padding: 0 6px;
  background: transparent url(../images/arrowUpActive.png) no-repeat 5px center;
}
.hoverarrow:hover {
  cursor: pointer;
  background: #c6c6c6;
  .arrow-down-default {
    background: transparent url(../images/arrowDownActive.png) no-repeat 5px center;
  }
}

.hoverarrow {
  position: relative;
  &:hover {
    .keliu-sort {
      position: absolute;
      top: 42px;
      left: 0;
      display: block;
    }
    .citylist-keliu ,.keliu-city{
      position: absolute;
      top: 42px;
      left: 0;
      display: block;
    }
  }
}

.keliu-sort ,.keliu-city{
  display: none;
  width: 100%;
  box-shadow: 0 0 10px #bcbcbc;
  li {
    line-height: 42px;
    background: #fff;
    &:hover {
      background: rgb(219,89,207);
      color: #fff;
      cursor: pointer;
    }
  } 
}

.citylist-keliu {
  display: none;
  width: 430px;
  background: #fff;
  cursor: default;
  ul {
    text-align: left;
  }
  li {
    display: inline-block;
    padding: 2px;
    color: #878787;
    text-align: center;
    width: 3em;
  }
}

.citylist-province {
  position: relative;
  &:hover {
    background: #878787;
    color: #fff;
    cursor: pointer;
    ul {
      position: absolute;
      left: 0;
      top: 1.8em;
      display: block;
      z-index: 10;
    }
  }
}

.citylist-province {
  ul {
    display: none;
    width: 475px;
    background: #878787;
  }
  li {
    display: inline-block;
    color: #fff;
    width: 3em;
    &:hover {
      color: #fff;
      background: rgb(219,89,207);
    }
  }
}










