$bg: #f8f8f8;

.view-bg {
  background-color: $bg;
}

.form-ib {
  @extend %inline-block;
}
.page-detail-value{
  width: auto;
}
%clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
  }
  zoom: 1;
}

// form-container
.validate-form {
  margin-bottom: 15px;
  padding: 16px 20px 30px;
  font-size: 12px;
  background-color: #f8f8f8;
  @extend %clearfix;
  .sex {
    &, input {
      margin-right: 5px;
      margin-top: 0;
    }
  }
}

.form-disabled {
  pointer-events: none;
  opacity: 0.5;
}


// form-row
.form-group {
  margin-top: 14px;
  &:first-child {
    margin-top: 0;
  }
  @extend %clearfix;
}

//微信卡包卡券相关样式

.form-describe {
  margin-top: 5px;
  margin-left: 40px;
  padding: 5px 10px;
  width: 640px;
  color: #aaa;
  border: 1px dashed #e0e0e0;
  border-radius: 4px;
}

.form-describe-y {
  width: 470px;
}

.coupon-channel {
  margin-right: 34px;
  span {
    vertical-align: middle;
  }
}

.coupon-send {
  display: inline-block;
  margin-right: 34px;
  line-height: 30px;
  span {
    vertical-align: middle;
  }
}

.coupon-explain {
  float: left;
  font-size: 12px;
  textarea {
    display: block;
    width: 300px;
    height: 80px;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    box-sizing: border-box;
  }
}

// form-label
.form-group .control-label {
  float: left;
  width: 90px;
  line-height: 30px;
  text-align: right;
  margin-right: 15px;
}

// form-value
.control-value {
  position: relative;
  float: left;
  line-height: 30px;
  font-size: 12px;
  width: 320px;
}

// form-value-type-01
.datepicker-wrap {
  position: relative;
  width: 252px;
  @extend %inline-block;
  vertical-align: middle;
}

// form-value-type-02
.mini-control {
  width: 120px;
}

#form-offline-charge-wrap {
  width: 180px;
}

// form-value-type-03
.auto-control {
  width: auto;
}

// form-value-type-04
.auto-vertical-control {
  height: auto;
}

.indent-control {
  margin-left: 95px;
}

// form-value-helper
%control-helper {
  @extend %inline-block;
  line-height: 30px;
  height: 30px;
}
.control-helper {
  @extend %control-helper;
  color: rgb(135, 135, 135);
  margin-left: 10px;
}

// form-value-remark
.control-remark {
  @extend %control-helper;
}

.control-value input[type=""],
.control-value input[type="password"],
.control-value input[type="number"],
.control-value input[type="text"] {
  height: 28px;
  line-height: 28px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'microsoft yahei';
  &.control-bottom-line {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #000;
    width: 120px;
    background: transparent;
    text-align: center;
    outline: none;
  }
}

.submit-btn {
  margin-left: 105px; 
  padding: 10px 25px; 
  display: inline-block; 
  color: #fff;
  background-color: #cc73cf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover,
.submit-btn:active {
  background-color: #86339a;
}

.coupon-range-item {
  float: left;
  margin-right: 5px;
}

.line {
  width: 32px;
  margin: 0 7px;
  display: inline-block;
  vertical-align: middle;
}

.line span {
  border-top: 1px solid #999;
  display: block;
}

/* checkbox/radio group */
.checkbox-group,
.radio-group {
  line-height: 30px;
  margin-right: 25px;
  display: inline-block;
}
.checkbox-group.last {
  margin: 0;
}
.checkbox-group label,
.radio-group label {
  overflow: hidden;
  cursor: pointer;
}
.checkbox-group input,
.radio-group input {
  display: none;
}
.checkbox-group .box,
.radio-group .box {
  float: left;
  width: 18px;
  height: 18px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  background: none;
  background-color: #fff;
  margin: 5px 8px 0 0;
}
.checkbox-group.selected .box,
.radio-group.selected .box {
  background-image: url("../images/checkbox-selected.png");
  background-position:  50% 50%;
  background-repeat:  no-repeat;
}

select {
  line-height: 28px;
  height: 28px;
  font: inherit;
  font-size: 12px;
  padding-left: 5px;
  margin-right: 5px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

// 会员美体美容相关
.form-member-beauty {
  margin-bottom: 20px;
  font-size: 12px;
  .form-member-beauty-inner {
    padding: 10px 0;
    background-color: #f8f8f8;
  }
  p {
    font-size: 14px;
    line-height: 30px;
  }
  dl {
    overflow: hidden;

  }
  dt, dd {
    float: left;
    line-height: 30px;
    vertical-align: middle;
  }
  dt {
    width: 114px;
    text-align: right;
  }
  label {
    margin-left: 15px;
  }
  input {
    vertical-align: baseline;
  }
  select {
    width: 140px;
    outline: none;
  }
  .inputtext-long, .inputtext-medium, .inputtext-short {
    padding: 0 6px;
    width: 600px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    outline: none;
  }
  .inputtext-medium {
    width: 140px;
  }
  .inputtext-short {
    width: 38px;
    height: 24px;
    line-height: 24px;
  }
  .submit-btn {
    margin-left: 129px;
  }
}

.form-member-body-content, .form-member-facial-content {
  .validate-form {
    background-color: #fff;
  }
  .form-group {
    margin-top: 0;
    margin-bottom: 20px;
    label {
      width: 114px;
    }
  }
}

.detail-member-basic-content {
  .submit-btn {
    margin-left: 82px;
  }
}

