.wei-reply_main{
  background-color: #f8f8f8;
  width: 100%;
}
.tabHide{
  display: none;
}
.tabShow{
  display: block;
}
span.name_word,span.key_words {
  display: inline-block;
}
span.enter_key {
  color: #999;
  margin-left:30px;
  display: inline-block;
  margin-top:7px;
}

.key_words_list {
  //margin-top:10px;
  li {
    color: #fff;
    float: left;
    margin-left:10px;
    background-color: #fe978c;
    height: 25px;
    padding:0 10px;
    position: relative;
    text-align: center;
    line-height:25px;
    border-radius: 5px;
    img {
      width:15px;
      position: absolute;
      top:-3px;
      right:-3px;
      cursor: pointer;
    }
  }
  li:nth-child(1) {
    margin-left:41px;
  }
}
.form-group {
  span.watch_demo {
    display: inline-block;
    margin-top:7px;
    margin-left:30px;
    cursor: pointer;
  }
}

