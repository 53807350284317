#page-package{
  .role-hidden{
    display: none;
  }
  .role-hidden-show{
    display: none;
  }
  .newsection{
    border: 1px solid #ebebeb;
    background-color: #fff;
    margin-left: 68px!important;
    padding: 0 20px;
    border-radius: 3px;
    line-height: 50px!important;
    width: 550px;
    .del-this{
      float: right;
      cursor: pointer;
    }
    .section-skuname{
      width: 16em;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 20px;
      position: relative;
      top: 5px;
    }
    .section-price{
      margin: 0 5px;
      width: 75px;
      display: inline-block;
    }
  }
  .selected-certain-button{
    margin-top: 10px;
    color: #cc73cf;
    margin-left: 20px;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
    width: 56px;
  }
}