#page-keliu{
  .current-view {
    &, &:hover {
      cursor: pointer;
      color: #cc73cf;
      background-color: #fff;
      .arrow {
        background: url(../images/arrow-current.png) no-repeat 0 0;
      }
      position: relative;
      &:after {
        @extend %sidebar-line;
      }
    }
  }
  %sidebar-line {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    width: 4px;
    background-color: #cc73cf;
  }
}
.graph-keliu {
  margin-top: 30px;
  padding: 30px 53px;
  height: 500px;
  background: rgb(248,248,248);
  margin-bottom:50px;
}

.graph-keliu-distribution,.graph-keliu-data {
  h1{
    margin-bottom: 30px;
    color: #333333;
    font-size: 18px;
  }
}

%square14 {
  @extend %inline-block;
  width: 14px;
  height: 14px;
}

%rectangle {
  @extend %inline-block;
  padding: 4px 8px;
  border-radius: 2px;
}

.graph-keliu-amount {
  margin-top: 10px;
  max-width: 860px;

}

.graph-keliu-amount-left {
  position: relative;
  bottom: -26px;
  float: left;
  height: 30px;
  line-height: 30px;
  span {
    margin-right: 6px;
  }
  .square-pink {
    @extend %square14;
    margin-left: 2px;
    margin-bottom: -1.5px;
    background: rgb(219,89,207);
  }
  .square-blue {
    @extend %square14;
    margin-left: 12px;
    margin-bottom: -1.5px;
    background: #00a1e0;
  }
}

.graph-keliu-amount-right {
  float: right;
  margin-right: 100px;
  .rectangle-pink {
    margin-right: 4px;
    @extend %inline-block;
    @extend %rectangle;
    background: rgb(219,89,207);

  }
  .rectangle-blue {
    @extend %inline-block;
    @extend %rectangle;
    background: #00a1e0;
  }
}


.graph-keliu-data-day {
  span {
    @extend %inline-block;
    padding: 4px 12px;
    background: #fff;
    border-radius: 18px;
    margin-right: 8px;
    &:hover {
      background: #00a1e0;
      cursor: pointer;
    }
  }
}
.graph-keliu-data-day .graph-keliu-data-day7{
  background: #00a1e0;
}
.square16{
  margin-right: 4px;
  background: #db59cf;
  padding: 4px 8px;
  border-radius: 2px;
}
.square17{
  background: #00a1e0;
  padding: 4px 8px;
  border-radius: 2px;
}
.chooseTime{
  display: inline-block;
  height:30px;
  width:auto;
  padding:0 10px;
  margin-left: 10px;
  position: relative;
  background: #fff;
  border:1px solid #eee;
  border-radius: 5px;
}
.timeBox{
  position: absolute;
  top: 35px;
  left: 10px;
  border-radius: 5px;
  width: 300px;
  height: 108px;
  background-color: #fff;
  box-shadow: 0 0 10px #bcbcbc;
  z-index: 999;
  display: none;
}
.timeS,.timeE{
  width: 115px;
  height: 30px;
  text-align: center;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #e8e8e8;
  margin:10px 0 30px 10px;
  outline: none;
}
.cancelT,.submitT{
  float:right;margin-right: 20px;
}
::-webkit-inner-spin-button { display:none }
::-webkit-calendar-picker-indicator {
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px #fff, 0 1px #eee;
  background-color: #eee;
  background-image: -webkit-linear-gradient(top, #f0f0f0, #e6e6e6);
  color: #666;
}
