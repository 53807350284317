.yunying-form {
  font-size: 14px;
  background-color: #f8f8f8;
  padding: 20px;
}

.form-group-y {
  margin-top: 18px;
}

.control-label-y {
  float:left;
  width: 80px;
  line-height: 30px;
  text-align: right;
}

.control-value-y {
  display: inline-block;
  margin-left: 15px;
  line-height: 28px;
  vertical-align: middle;
}

%border {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background: #fff;
}

.w60 {
  width: 60px;
}

.control-value-y textarea {
  padding: 8px;
  width: 320px;
  height: 80px;
  @extend %border;
}

.submit-btn-y {
  display: inline-block;
  margin-left: 95px;
  padding: 8px 25px;
  border: none;
  border-radius: 4px;
  background-color: #cc73cf;
  color: #fff;
  cursor: pointer;
}
