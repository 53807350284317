.pagination {
  display: none;
  font-size: 0;
  text-align: center;
  margin: 30px auto;
  li {
    font-size: 12px;
    @extend %inline-block;
    margin: 0 4px;
    &.current a {
      cursor: default;
      color: #fff;
      background-color: #cc73cf;
    }
  }
  a {
    display: block;
    background-color: #fff;
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid #ececec;
    &:hover {
      background-color: #d69ed8;
      color: #fff;
    }
  }
}
