#refund {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -160px;
  border: 1px solid #ddd;
  width: 320px;
  background-color: #fff;
  padding: 0 15px;
  display: none;
}

.refund {
  &-head {
    line-height: 36px;
  }
  &-body-item {
    margin: 5px 0;
  }
  &-foot {
    margin: 5px 0 10px;
    text-align: right;
    line-height: 24px;
    a {
      margin-left: 10px;
    }
  }
}
