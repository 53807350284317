//框架
#fooo{
  html, body {
    margin:0;
    padding:0;
    font-family: "Lucida Grande", Verdana;
    font-size: 0.9em;
    text-align: center;
    background-color:#F2F2F2;
  }

  #orgchart {
    background: none repeat scroll 0 0 #F6F6F6;
    border: 1px solid #EFEFEF;
    height: 600px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;
    z-index: 300;
  }

  #orgchart li {
    cursor: pointer;
    float: left;
    list-style: none outside none;
    position: relative;
  }
  /* Entire mail form. Be very explicit with sizes to avoid IE6 textfield bug. */
  .mailform {
    width: 80%;
    /* removed again due to issues with IE6 and 7
    padding-left: 10%;
    padding-right: 10%;
    */
  }

  /* Field name in mail forms */
  .fieldname {
    padding: 12px 5px 5px 5px;
  }

  /* Required field name in mail forms */
  .requiredfieldname {
    padding: 12px 5px 5px 5px;
    font-weight: bold;
  }

  /* Applied to the fields in mail form */
  .formfields {

  }

  /* Applied to the DIV that contains buttons in mail form */
  .fieldbuttons {
    padding-top: 1em;
    padding-bottom: 2em;
    text-align: center;
  }

  /* A file included by the some modules... */
  .includeitem {
    padding: 0px;
    border-bottom: 0px solid #D4D0C8;
  }

  /* ... its title, ... */
  .includetitle {
    margin-top: 0;

    padding: 0px;
    color: #2B99E6;
  }

  /* ... its date and link ... */
  .includedate, .includereadmore {
    border-top: 1px dotted #303030;
    padding: 3px 2px 7px;
    text-align: right;
  }

  .includedate, .includereadmore a {
    font-size: 14px;
    text-align: right;
  }

  /* ... its text */
  .includetext {
    padding: 0px;
  }

  /* Editing table */
  table.meshcmseditor {
    border-top: 1px dotted silver;
    border-left: 1px dotted silver;
  }

  table.meshcmseditor th, table.meshcmseditor td {
    padding: 2px;
    border-right: 1px dotted silver;
    border-bottom: 1px dotted silver;
  }

  table.meshcmseditor th {
    text-align: center;
  }

  table.meshcmseditor tr:hover {
    background-color: #edeeef;
  }

  /* Editing DIVs */
  div.meshcmseditor {
    padding: 1em;
    border: 1px dotted silver;
    margin-bottom: 1em;
  }

  div.meshcmsfieldlabel {
    border-bottom: 1px dotted silver;
    margin-top: 0.8em;
    margin-bottom: 0.2em;
  }

  div.meshcmsfield {
    white-space: nowrap;
  }

  div.meshcmscheckbox {
    margin: 1em 0 0 1em;
  }

  div.meshcmsbuttons {
    margin-top: 0.8em;
    border-top: 1px dotted silver;
    padding-top: 0.2em;
    text-align: center;
  }

  /* This style causes issue with IE6
  fieldset.meshcmseditor {
   margin: 0 0.5em 1em 0.5em;
   padding: 0.2em 1em 1em 1em;
  }
  */

  fieldset.meshcmseditor legend {
    margin-bottom: 0.5em;
  }

  a.meshcmspanelicon {
    /*width: 12em;*/
    display: block;
    float: left;
    margin: 0.5em;
    padding: 5px;
    border: 0;
  }

  a.meshcmspanelicon img {
    float: left;
    margin-right: 0.5em;
  }

  a.meshcmspanelicon:hover {
    text-decoration: none;
    padding: 4px;
    border: 1px solid silver;
  }

  /* style for the thumbnail box in the lightbox image gallery */
  .imagebox {
    float: left;
    margin: 0 30px 30px 0;
  }

  .clearLeft {
    clear: left;
  }

  /* IE6 holly hack \*/
  * html .lightbox {height: 1%;}
  /* End IE6 holly hack */

  .meshcmsinfo {
    border: 1px dotted silver;
    padding: 0.5em;
    font-style: italic;
  }

  /* media player */

  .meshcmsMediaPlayerScreen {
    width: 400px;
    height: 300px;
  }

  .meshcmsMediaPlayer_vertical .meshcmsMediaPlayerScreen {
  }

  .meshcmsMediaPlayer_horizontal .meshcmsMediaPlayerScreen {
    float: left;
  }

  .meshcmsPlayList {
    background-color: #7b7b7b;
    overflow: auto;
    font-family: sans-serif;
  }

  .meshcmsMediaPlayer_vertical .meshcmsPlayList {
    width: 400px;
    height: 180px;
  }

  .meshcmsMediaPlayer_horizontal .meshcmsPlayList {
    width: 202px;
    height: 300px;
  }

  .meshcmsPlayList a {
    float: left;
    width: 176px;
    height: 56px;
    overflow: hidden;
    border: 1px solid #888888;
    padding: 2px;
    margin: 1px;
    text-decoration: none;
    color: white;
  }

  .meshcmsPlayList a.playing {
    background-color: #afafaf;
  }

  .meshcmsPlayList a:hover {
    background-color: #afafaf;
    text-decoration: none;
  }

  .meshcmsPlayList img {
    float: left;
    border: 1px solid #5a5a5a;
    margin-right: 2px;
  }

  .moduletitle{
    font-size: 14px;
    font-family: arial;
    padding: 5px 0px;
  }

  .modulebody{
    font-size: 12px;
    font-family: arial;
    padding: 5px 0px;
  }

  .breadcrumbs{
    margin: 0 auto;
    width: 960px;
  }

  .breadcrumbscontent{
    width: 100%;
    float: left;
  }

  /* player container */
  #player {
    height:425px;
    width:320px;
    border: 1px solid #fff;
    outline: 1px solid #333;
    -moz-outline-radius:4px;
    cursor:pointer;
    float:left;
    text-align:center;
    margin-right:15px;
    padding: 0px;
  }

  /* play button */
  #player img {
    margin-top: 97px;
  }

  /* info area */
  #player div.info {
    height:40px;
    background:#000 url(/gbin1/admin/modules/video_player/h80.png) repeat-x;
    opacity:0.7;
    color:#fff;
    margin-top:45px;
    text-align:left;
    padding:5px 15px;
    font-family:"bitstream vera sans","trebuchet ms";
    font-size:12px;
    border-top:1px solid #ccc;
    margin-top:80px;
  }

  /* duration data inside info area */
  #player div.info span {
    color:#99FF99;
    display:block;
    font-weight:bold;
  }
  .jit-autoadjust-label {
    padding: 15px;
  }

  #update, #restore {
    text-align: center;
    width: 100px;
    margin:0px 35px 10px 35px;
  }

  .button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font: 14px / 100% Arial, Helvetica, sans-serif;
    padding: 0.5em 1em 0.55em;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    border-radius: 0.5em;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  .button:hover {
    text-decoration: none;
  }

  .button:active {
    position: relative;
    top: 1px;
  }

  /* white */
  .white {
    color: #606060;
    border: solid 1px #b7b7b7;
    background: #fff;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ededed));
    background: -moz-linear-gradient(top,  #fff,  #ededed);
    filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');
  }

  .white:hover {
    background: #ededed;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#dcdcdc));
    background: -moz-linear-gradient(top,  #fff,  #dcdcdc);
    filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dcdcdc');
  }

  .white:active {
    color: #999;
    background: -webkit-gradient(linear, left top, left bottom, from(#ededed), to(#fff));
    background: -moz-linear-gradient(top,  #ededed,  #fff);
    filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff');
  }
}


















//正文
.page-view-privilege{
  .activity-shop ul{
    max-height: 300px;
  }
  .th ul{
    margin: 0;
    list-style: none;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
  }
  #activity-form-shop table ul{
    z-index: 1;
    margin-left: 30%;
    list-style: none;
    width: 40%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
  }
  .th ul li{
    font-size: 12px;
    font-weight: 400;
    height: 50px;
    line-height: 48px;
    border:1px solid #ebebeb;
    border-top: 0;
    background: white;
    color: #666666;
  }
  #activity-form-shop table ul li{
    position: relative;
    font-size: 12px;
    font-weight: 400;
    height: 30px;
    line-height: 28px;
    border: 1px solid #ebebeb;
    border-top: 0;
    background: white;
    color: #666666;
  }
  #activity-form-shop table ul li:hover,
  .th ul li:hover{
    background: #cc73cf;
    color: white;
  }
  ul li div p{
    height: 100%;
  }
  .th ul{
    display:none;
  }
  .thp ul{
    display:none;
  }
  .active ul{
    display: block;
  }
  #page-view-privilege .loading{
    height: 104px;
    background: url(../images/requesting.gif) no-repeat 50% 10%;
  }
  input:focus{
    outline: none;
  }
  .shop-number-button{
    font-size: 14px;
    margin-left: 20px;
    color: black;
    background: white;
    border: 1px dashed #cccccc;
    border-radius: 3px;
    height: 27px;
    padding: 0 10px;
    display: none;
  }
  .shop-number-button-look{
    font-size: 14px;
    margin-left: 20px;
    color: black;
    background: white;
    border: 1px dashed #cccccc;
    border-radius: 3px;
    height: 26px;
    padding: 0 10px;
  }
  .activity-city-th,
  .activity-sname-th,
  .activity-stype-th,
  .activity-snum-th {
    width: 25%;
    height: 42px;
    line-height: 42px;
    color: #505050;
    font-size: 14px;
    font-weight: 700;
    background: #ebebeb;
    text-align: center;
    position: relative;
  }
  #activity-form-shop{
    width: 100%;
    min-height: 480px;
  }
  #activity-form-shop table{
    width: 100%;
  }
  .activity-tbody-td{
    width: 25%;
    height: 42px;
    line-height: 42px;
    font-size: 12px;
    text-align: center;
    position: relative;
  }
  .activity-city-zd img{
    position: absolute;
    top: 6px;
    left: 5px;
  }
  .activity-city-all img{
    position: absolute;
    top: 6px;
    left: 5px;
  }
  .activity-tbody-td img{
    position: absolute;
    top: 16px;
    left: 10px;
  }
  .activity-shop-number{
    width: 286.5px;
    height: 50px;
    line-height: 50px;
    background: transparent;
    text-align: center;
    position: relative;
  }
  .activity-shop-number img{
    position: absolute;
    top: 16px;
    left: 10px;
  }
  .add-shop-num{
    color: #cc73cf;
    margin-left: 20px;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
    width: 56px;
  }
  .activity-shai{
    width: 40%;
    margin-left: 30%;
    text-align: center;
  }
  .snake ul{
    display: none;
  }
  .skit ul{
    display: block;
  }
  //添加分部
  .edit-form{
    background: #f8f8f8;
    padding: 15px 0 40px 40px;
  }
  .look-form{
    background: #f8f8f8;
    padding: 15px 0 40px 40px;
  }
  .site-form{
    background: #f8f8f8;
    padding: 15px 0 40px 40px;
  }
  .update-form{
    background: #f8f8f8;
    padding: 15px 0 40px 40px;
  }
  .sheBt{
    color: #cc73cf;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
  }
  .updateBt{
    color: #cc73cf;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
  }
  .dateBt{
    color: #999;
    background: white;
    border: 1px solid #999;
    border-radius: 3px;
    height: 28px;
  }
  .lookBt{
    color: #cc73cf;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
  }
  .xuanBt{
    font-size: 14px;
    margin-left: 20px;
    color: black;
    background: white;
    border: 1px dashed #cccccc;
    border-radius: 3px;
    height: 27px;
    padding: 0 10px;
  }
  .bzText{
    color: #999;
    margin: 5px 0 0 88px;
  }
  .edit-submit{
    margin: 50px 0 0 88px;
    color: white;
    background: #cc73cf;
    width: 110px;
    border: none;
    border-radius: 3px;
    height: 28px;
  }
  .update-submit{
    margin: 50px 0 0 88px;
    color: white;
    background: #cc73cf;
    width: 110px;
    border: none;
    border-radius: 3px;
    height: 28px;
  }
  .site-submit{
    margin: 50px 0 0 88px;
    color: white;
    background: #cc73cf;
    width: 110px;
    border: none;
    border-radius: 3px;
    height: 28px;
  }
  .text-label{
    width: 85px;
    text-align: right;
    border: none;
    background: transparent;
    font-size: 14px;
    font-family: '微软雅黑';
  }
  .text-edit{
    height: 28px;
    line-height: 28px;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    padding: 0 10px;
    box-sizing: border-box;
    font-family: 'microsoft yahei';
  }
  .relative{
    position: relative;
  }
  .layer-detial{
    z-index: 999;
    width: 104px;
    position: absolute;
    top: 35px;
    left: 50px;
    box-shadow: 0 0 10px #bcbcbc;
    display: none;
    li{
      width: 100%;
      height: 30px;
      line-height: 28px;
      background: white;
      color: #333;
      text-align: center;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      &:hover{
        background: #cc73cf;
        color: white;
      }
    }
  }
}