.recommend-list,.follow-list{
  tr:nth-child(2n){
    background: #f8f8f8;
  }
  tr:hover{
    background: #e6e6e6;
  }
  table{
    width: 100%;
  }
  th{
    height: 42px;
    line-height: 42px;
    color: #505050;
    font-size: 12px;
    font-weight: 700;
    background: #ebebeb;
    text-align: center;
    position: relative;
  }
  td{
    height: 90px;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    color:#666666;
    margin: 0;
  }
  .tr_p{
    line-height: 30px;
    height: 30px;
  }
  .tr_p-two{
    line-height: 40px;
    height: 35px;
  }
  .caozuo_span{
    cursor: pointer;
    color: #cc73cf;
  }
}
.form-div-form{
  padding: 20px 30px;
  background: #f8f8f8;
}
.control-label{
  font-size: 12px;
}
input[type="radio"]{
  position: relative;
  top:-2px;
}
