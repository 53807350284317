#form-package-content,
#form-edit-content,
#spu-form {
  .section {
    overflow: hidden;
    line-height: 28px;
    margin: 5px 0;
  }
  .section > label {
    float: left;
    margin-right: 10px;
    min-width: 60px;
    text-align: right;
  }

  input {
    padding: 5px 4px;
  }
  .spu-table input {
    padding: 10px 4px;
  }

  .selected {
    background-color: #cc73cf;
    color: #fff;
  }
  .selected input{
    color: #fff;
  }
  .table-warning {
    color: red;
  }

  #categorys select {
    width: 8em;
  }

  .add-vector-item {
    margin: 0;
    margin-right: 10px;
    line-height: 24px;
  }

  #btn-spu-save {
    padding: 6px 15px;
    margin: 20px 10px;
  }
  #spu-vector {
    width: 100%;
  }

  .spu-have-no-vector {
    display: none;
  }
  
  .spu-table-action .button {
    padding: 0 10px;
    line-height: 24px;
  }

}

.requesting {
  pointer-events: none;
  opacity: 0.5;
}

#spuname {
  width: 320px;
}

#sku-add-content {
  .validate-form {
    padding-bottom: 16px;
  }
}

#btn-spuid-add {
  margin-left: 10px;
  padding: 8px 25px;
}

.spu-table-action {
  overflow: hidden;
  &-main {
    float: left;
  }

  &-side {
    overflow: hidden;
    text-align: right;
  }
  li {
    float: left;
    margin-right: 10px;
  }
  .spu-table-action-label {
    float: left;
    cursor: pointer;
    margin-right: 5px;
    color: #cc74cf;
  }
  &-control {
    float: left;
    display: none;
  }
}

