/* datepicker */
.ui-widget-content {
  font: 90% "Trebuchet MS", sans-serif;
}

.ui-datepicker-trigger {
  position: absolute;
  top: 8px;
  right: 2px;
  width: 17px;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background-color: #ededed;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  color: #fff;
  background-color: rgba(204, 115, 207, .6);
  border-color: rgba(204, 115, 207, .6);
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  color: #fff;
  background-color: #cc73cf;
  border-color: #cc73cf;
}

.ui-datepicker .ui-datepicker-title select,
.ui-datepicker th {
  font-size: 14px;
  height: auto;
}

.ui-datepicker .ui-datepicker-title select {
  padding-left: 7px;
}

