.page-view-appointment{
  .input-form-change{
    border: none!important;
    background: transparent;
    position: relative;
    bottom: 2px;
  }
  tr{
    cursor: pointer;
  }
  th ul{
    position: absolute;
    display: none;
  }
  th.active ul{
    display: block;
  }
  #appointment-appoint-list td span{
    cursor: pointer;
    color: #cc73cf;
  }
  .width155{
    width: 155px!important;
  }
  .class-button-add{
    position: relative;
    top:1px;
    margin-top: 0!important;
  }
  .class-button-cut{
    cursor: pointer;
    margin-left: 5px;
  }
  .width-420{
    width: 420px!important;
  }
  .floatL{
    float: left;
  }
  .bRadius-3{
    border-radius: 3px;
  }
  .mr-5{
    margin-right: 5px;
    height: 16px;
    width:16px;
  }
  .mr-30{
    margin-right: 30px;
    line-height: 16px;
  }
  .appoint-date{
    margin-top: 15px;
  }
  .appoint-date-list{
    cursor: pointer;
    padding: 10px 0;
    width: 10%;
    text-align: center;
    background: #ddd;
    border-right: 1px solid #fff;
    box-sizing: border-box;
    p:first-child{
      margin-bottom: 12px;
    }
  }
  .appoint-time-box{
    margin-top: 4px;
    overflow: hidden;
  }
  #appoint-box-color-content{
    overflow: auto;
    padding-bottom: 150px;
    max-height: 65vh;
    box-sizing: border-box;
  }
  .scroLe{
    position: absolute;
    top:0;
    left: 0;
    line-height: 40px;
    height: 40px;
    width: 120px;
    background: #ebebeb;
    border-bottom: 2px solid #fff;
    font-size: 14px;
    text-align: center;
  }
  .activeP{
      background: #ebebeb;
  }
  .appoint-spec-time-box{
    width:50px;
    float: left;
    line-height: 40px;
    background: #ebebeb;
    text-align: center;
  }
  .appoint-technician-time{
    float: left;
    text-align: center;
    margin-right: 4px;
    ul{
      li{
        width: 100px;
        line-height: 52px;
        background: #ebebeb;
        margin-bottom: 1px;
      }
      p{
        line-height: 35px;
        height: 26px;
      }
      div{
        height: 26px;
        line-height: 20px;
        color: #999;
      }
    }
  }
  .appoint-technician-list{
    position: relative;
    margin-top: 4px;
    float: left;
    text-align: center;
    margin-right: 4px;
    max-height: 70vh;
    overflow: hidden;
    ul{
      position: relative;
      max-height: 65vh;
      padding-bottom: 150px;
      top:42px;
      left: 0;
      li{
        width: 120px;
        background: #ebebeb;
        margin-bottom: 1px;
        font-size: 14px;
        p{
          line-height: 35px;
          height: 26px;
          overflow: hidden;
        }
      }
      div{
        line-height: 20px;
        height: 26px;
        color: #999;
      }
    }
  }
  #appoint-technician-list{
    float: left;
    text-align: center;
    margin-right: 1px;
    ul{
      li{
        width: 120px;
        background: #ebebeb;
        margin-bottom: 1px;
        height: 52px;
      }
      div{
        color: #999;
        height:26px;
        line-height: 20px;
      }
      p{
        height: 26px;
        line-height: 35px;
      }
    }
  }
  .appoint-box-color{
    float: left;
    width:49px;
    line-height: 52px;
    text-align: center;
    background: #00a3e8;
    margin-right:1px;
    margin-bottom: 1px;
    cursor: pointer;
    height: 52px;
    position: relative;
    div.appoint-message{
      z-index: 2;
      display: none;
      position: absolute;
      width: 200px;
      top:25px;
      left:25px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 0 10px #bcbcbc;
      text-align: left;
      padding-bottom: 10px;
      p{
        width: 200px;
        background: #ebebeb;
        margin-bottom: 22px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      div.appoint-message-name{
        margin-left: 20px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        background-image: url(../images/appoint-message-name.png);
        background-size: 14px;
        background-position: 0 50%;
        background-repeat: no-repeat;
      }
      div.appoint-message-tel{
        margin-left: 20px;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 20px;
        padding-left: 20px;
        background-image: url(../images/appoint-message-tel.png);
        background-size: 14px;
        background-position: 0 50%;
        background-repeat: no-repeat;
      }
      div.appoint-message-serve{
        margin-left: 20px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
        padding-left: 20px;
        background-image: url(../images/appoint-message-serve.png);
        background-size: 14px;
        background-position: 0 50%;
        background-repeat: no-repeat;
      }
    }
    span{
      height: 52px;
      width: 50%;
      font-size: 12px;
      display: none;
    }
  }
  .appoint-box-color:hover{
    div.appoint-message{
      display: block;
    }
    span{
      display: inline-block;
    }
  }
  .bRadius-left{
    width: 110px;
    padding: 0 5px;
    line-height: 60px;
    border-top-left-radius: 3px;
    float: left;
    margin-right: 1px;
    background: #ddd;
    text-align: center;
  }
  .bRadius-right{
    float: left;
    width: 90px;
    padding: 0 5px;
    line-height: 60px;
    border-top-right-radius: 3px;
    background: #ddd;
    margin-right: 4px;
    text-align: center;
  }
  #appoint-date div:last-of-type{
    border-top-right-radius: 3px;
  }
   .click_sidebar .appoint-date-list{
     width:7.89%!important;
   }
  #appoint-date .appoint-date-list{
    width:8.25%;
    padding: 0;
    border-right: 1px solid #fff;
    box-sizing: border-box;
    p{
      height: 30px;
    }
    p:first-child{
      line-height: 40px;
      margin-bottom: 0;
    }
    p:last-child{
      line-height: 25px;
    }
  }
  .time-box-appoint{
    cursor: pointer;
    float: left;
    height: 52px;
    line-height: 52px;
    background: #c8eac8;
    width: 10%;
    border-right: 1px solid #fff;
    box-sizing: border-box;
    color: transparent;
    text-align: center;
    span{
      height: 100%;
      width: 100%;
      font-size: 12px;
      display: none;
      color: #fff;
      background: #8fcc8f;
    }
  }
  .time-box-appoint:hover{
    span{
      display: inline-block;
      float: left;
    }
  }
  .time-box-appoint-miss:hover{
    span{
      float: left;
      display: inline-block;
    }
  }
  #appoint-techni-content{
    float: left;
    width: 82.5%;
  }
  .time-box-appoint-miss{
    cursor: pointer;
    float: left;
    line-height: 52px;
    height: 52px;
    width: 10%;
    border-right: 1px solid #fff;
    box-sizing: border-box;
    color: transparent;
    text-align: center;
    background: #ebebeb;
    span{
      height: 100%;
      width: 100%;
      font-size: 12px;
      display: none;
      color: #fff;
      background: #bbb;
    }
  }
  #appoint-date-content{
    line-height: 50px;
    background: #f8f8f8;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .sidebar{
    margin-left: -220px;
  }
  th{
    position: relative;
  }
  th ul{
    z-index: 1;
    list-style: none;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
    top:42px;
    display: none;
    font-size: 14px;
    font-weight: 400;
    background: white;
  }
  th ul li:hover{
    background: #cc73cf;
    color: white
  }
  .activeU ul{
    display: block;
  }
  .activeU ul li{
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
  #header{
    display: none;
  }
  #ctrl-sidebar{
    display: block;
  }
  .activeU ul{
    display: block;
  }
  .appoint-layer{
    display: none;
    width:490px;
    padding: 30px 30px 0;
    border-radius:3px;
    background: #fff;
    border:1px solid #ebebeb;
    position: fixed;
    z-index: 11;
    left: 50%;
    top:70px;
    margin-left: -245px;
    box-sizing: border-box;
    .appoint-layer-p{
      font-size: 16px;
      color: #333;
      margin-bottom: 30px;
    }
    div.appoint-left{
      width:210px;
      box-sizing: border-box;
      padding: 10px 10px 0;
      color: #333;
      margin-bottom: 10px;
      border:1px solid #eee;
      background: #f8f8f8;
      cursor: pointer;
      p{
        margin-bottom: 10px;
      }
      p:nth-child(3){
        color: #999;
      }
      span{
        color:#f23030;
        margin-left: 15px;
      }
      div.img{
        float: right;
        width: 30px;
        height: 30px;
        background-image: none;
        background-size: contain;
        position: relative;
        top:-10px;
        right:-10px;
      }
    }
    div.appoint-left:nth-child(2n+1){
      float: left;
    }
    div.appoint-left-current{
      div.img{
        background-image: url('../images/appoint-right-left.png');
      }
    }
    div.appoint-left:nth-child(2n+2){
      float: right;
    }
    div.appoint-left-box::-webkit-scrollbar {
      width:0;
    }
    div.appoint-left-box{
      max-height: 375px;
      overflow-y: auto;
    }
    div.appoint-left-active{
      background: #fff!important;
      border:1px solid #e43b3b!important;
      div.img{
        background-image: url('../images/appoint-right-right.png')!important;
        background-size: contain!important;
      }
    }
    div.appoint-left:hover{
      background: #fff;
      border:1px solid #e43b3b;
      div.img{
        background-image: none;
      }
    }
    div.appoint-techni-certain{
      float: right;
      margin-left: 20px;
      background: #e0e0e0;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      font-size: 14px;
      line-height: 28px;
      padding: 0 15px;
      margin-bottom: 30px;
      cursor: pointer;
    }
    div.appoint-techni-quit{
      float: right;
      background: white;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      font-size: 14px;
      line-height: 28px;
      padding: 0 15px;
      cursor: pointer;
    }
  }
  .ArriveNumber_active{
    border-color: #cc73cf!important;
    color: #cc73cf;
  }
  .ArriveNumber{
    width: 50px!important;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .teachnician-choose{
    line-height: 28px;
    padding: 0 16px;
    background: #fff;
    color:#333;
    border:1px solid #ebebeb;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .teachnician-choose-miss{
    line-height: 28px;
    padding: 0 16px;
    border:1px solid #ebebeb;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    background: #ebebeb;
    color:#aaa;
    cursor: default;
  }
  .teachnician-choose-active{
    line-height: 28px;
    padding: 0 16px;
    background: #fff;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    color: #cc73cf;
    border:1px solid #cc73cf;
    cursor: pointer;
  }
  .serve-choose{
    line-height: 28px;
    padding: 0 16px;
    background: #fff;
    color:#333;
    border:1px solid #ebebeb;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .serve-choose-miss{
    line-height: 28px;
    padding: 0 16px;
    border:1px solid #ebebeb;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    background: #ebebeb;
    color:#aaa;
    cursor: default;
  }
  .serve-choose-active{
    line-height: 28px;
    padding: 0 16px;
    background: #fff;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    color: #cc73cf;
    border:1px solid #cc73cf;
    cursor: pointer;
  }
  .date-choose{
    line-height: 28px;
    padding: 0 16px;
    background: #fff;
    color:#333;
    border:1px solid #ebebeb;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .date-choose-miss{
    line-height: 28px;
    padding: 0 16px;
    border:1px solid #ebebeb;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    background: #ebebeb;
    color:#aaa;
    cursor: default;
  }
  .date-choose-active{
    line-height: 28px;
    padding: 0 16px;
    background: #fff;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    top:1px;
    margin-bottom: 10px;
    color: #cc73cf;
    border:1px solid #cc73cf;
    cursor: pointer;
  }
  .time-choose{
    cursor: pointer;
    line-height: 40px;
    width: 50px;
    text-align: center;
    background: #c8eac8;
    margin: 0 1px 1px 0;
    color: #333;
    float: left;
  }
  .time-choose-miss{
    line-height: 40px;
    width: 50px;
    text-align: center;
    background: #ebebeb;
    margin: 0 1px 1px 0;
    color: #333;
    float: left;
  }
  .time-choose-active{
    cursor: pointer;
    line-height: 40px;
    width: 50px;
    text-align: center;
    background: #c8eac8;
    margin: 0 1px 1px 0;
    color: #333;
    float: left;
    position: relative;
    .arrive_shop{
      display: block;
    }
  }
  .arrive_shop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8fcc8f;
    color: #fff;
    display: none;
  }
  #serve-group-content{
    margin-bottom: 14px;
  }
  #teachnician-group-content{
    margin-bottom: 14px;
  }
  #date-group-content{
    margin-bottom: 20px;
  }
  .overFlowY-70-vh::-webkit-scrollbar {
    width:0;
  }
  .overFlowY-70-vh{
    max-height: 70vh;
    overflow-y: scroll;
    margin-top: 4px;
  }
}