.mini-navi {
  -webkit-user-select: none;
  border-bottom: 1px solid #e2e2e2;
  overflow: hidden;
  color: #666;
  margin-bottom: 15px;

  &-return {
    @extend %inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    border-right: 1px solid #b2b2b2;
    padding: 0 18px 0 34px;
    background: url(../images/return.png) no-repeat 10px 50%;
  }

  &-return:hover {
    color: #cc73cf;
    background: url(../images/return-hover.png) no-repeat 10px 50%;
  }

  &-titles {
    @extend %inline-block;
    vertical-align: -2px;
    padding-left: 18px;
    font-size: 0;
    ul {
      margin-right: -12px;
    }
    li {
      cursor: default;
      font-size: 14px;
      display: inline-block;
      *display: inline;
      *zoom: 1;
      margin-right: 15px;
      line-height: 24px;
      &.current {
        color: #cc73cf;
      }
    }
  }

  &-button {
    @extend %inline-block;
  }

  input[type="submit"],
  button {
    font: inherit;
    font-size: 12px;
    line-height: 28px;
    vertical-align: middle;
    padding: 0 20px;
    border: 1px solid #878787;
    border-radius: 4px;
    color: #666;
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
    cursor: pointer;
    &:hover {
      background: #fff;
    }
    &:focus {
      outline: none;
    }
  }

  &-helper {
    @extend %inline-block;
    vertical-align: -2px;
  }

  &-search {
    @extend %inline-block;
    line-height: 28px;
    border-radius: 4px;
    border: 1px solid #878787;
    background: transparent url(../images/search.png) no-repeat 10px center;
    margin-left: 15px;
    input {
      // line-height: 28px;
      height: 28px;
      width: 200px;
      padding-left: 40px;
      padding-right: 10px;
      outline: none;
      border: 0;
      background: transparent;
      box-sizing: border-box;
    }
  }
}

.navi-side,
.navi-main {
  height: 50px;
  line-height: 50px;
}

.navi-side {
  float: right;
  button {
    margin-left: 15px;
  }
}

.navi-main {
  float: left;
  button {
    margin-right: 30px;
  }
}

.mini-navi-day {
  @extend %inline-block;
}

.mini-navi-calendar {
  position: relative;
  @extend %inline-block;
  input {
    height: 28px;
    margin: 0 10px;
    padding-left: 12px;
    border: 1px solid rgb(224,224,224);
    border-radius: 3px;
  }
  img {
    margin-top: 10px;
    right: 20px;
  }
}

.navi-day-before, .navi-day-after {
  display: inline-block;
  font: inherit;
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 12px;
  border: 1px solid #878787;
  border-radius: 4px;
  color: #666;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
  cursor: pointer;
  &:hover {
    background: #fff;
  }
  &:focus {
    outline: none;
  }
}

.navi-side-store {
  width: 160px;
  margin-left: 10px;
}
