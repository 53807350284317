
.wei_main{
  background-color: #f8f8f8;
  height: 430px;
  .v-maLeft{
    .maLeftT{
      height: 205px;
      position: relative;
      .maLeft-m{
        position: absolute;
        bottom:0;
        font-size: 12px;
        display: flex;
        p{
          margin-left: 30px;
          line-height: 40px;
          margin-right: 30px;
        }
          .maLeftAdd{
            li{
              >span{
                display: block;
                border-right:1px solid #ebebeb;

              }
            }
            >ul{
              box-sizing: border-box;
              display: flex;
              .leftLi{
                border-right:1px solid #e2e2e2;
              }
              >li{  //一级菜单li
                font: inherit;
                font-size: 12px;
                vertical-align: middle;
                color: #666;
                background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
                cursor: pointer;
                position: relative;
                line-height: 40px;
                height:40px;
                width: 90px;
                text-align: center;
                font-weight: 900;
                &:last-of-type{
                  border-right:1px solid #e2e2e2;
                }
                ol{
                  margin-left: -1px;
                  list-style: none;
                  position: absolute;
                  bottom:40px;
                li{
                  font-weight: 500;
                  background: #fff;
                  width: 89px;
                  border: 1px solid #e2e2e2;
                  border-bottom: none;
                  &:last-of-type{
                    border-bottom: 1px solid #e2e2e2;
                  }
                }

                }
              }
            }
          }
      }
    }
    .maLeftB{
      width: 410px;
      border:1px solid #ccc;
      background: #fff;
      padding:10px 30px;
      border-radius:4px;
      display: none;
      .bianTitle{
        text-align: center;
        font-size: 15px;
        line-height: 39px;
      }
      .bianX{
        .bianName{
          display: flex;
          p{
            line-height: 30px;
            font-size:12px;
            margin-bottom: 10px;
          }
          .Name{
            height: 28px;
            line-height: 28px;
            border: 1px solid #e0e0e0;
            border-radius: 2px;
            padding: 0 10px;
            width: 100%;
            box-sizing: border-box;
            position: relative;
            top: 3px;
          }
        }
        .bianUrl{
          display: flex;
          p{
            font-size: 12px;
          }
          .Url{
            width: 220px;
            position: relative;
            top:-5px;
            li{
              cursor: pointer;
              line-height: 30px;
            }
            li:last-child{
              margin-right: 11px;
            }
          }
        }
        .ziUrl{
          .textUrl{
            height: 28px;
            line-height: 28px;
            border: 1px solid #e0e0e0;
            border-radius: 2px;
            padding: 0 10px;
            width: 100%;
            box-sizing: border-box;
            margin-top: 10px;
          }
        }
        }
    }
   .maLeftTwo{
     width:410px;
     margin-top: 50px;
     border:1px solid #ccc;
     display: none;
     padding: 20px 30px ;
     background: #fff;
     border-radius:4px;
    .wei-title{
      font-size: 15px;
      line-height: 30px;
       text-align: center;
     }
     .maLeftT-m{
      >p{
        font-size:12px;
        display: flex;
        margin-bottom: 30px;
        .xiugai{
          width:54px;
          height:20px;
          margin-left: 20px;
          padding-left: 16px;
          font-size:12px;
        }
      }
       .zhu{
         margin-bottom: 25px;
         p:last-of-type{
           text-indent: 2em;
         }
       }
       .bianjiAdd{
         margin-bottom: 10px;
         >input{
           cursor: pointer;
           border:1px solid #ccc;
           border-radius: 5px;
           width: 80px;
           height: 20px;
           text-align: center;
           background-color: #fff;
           margin-left:20px;
         }
         >input:first-of-type{
          margin-left: 30px;
       }

       }
     }
   }
  }
  .v-maRight{
    padding-right: 26px;
    margin-left: 80px;
    ul{
      font-size: 12px;
      margin-top: 160px;

    }
  }
}
.bianjiAdd {
  padding-left: 50px;
  padding-bottom: 10px;
  > .button_1 {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
    padding: 5px 30px;
  }
  > .button_1:first-child {
    margin-right: 30px;
  }
  > .button_1:nth-of-type(2) {
    margin-right: 20px;
  }
}
.aaa{
  margin-left:65px;
}
.TwoName{
  height: 28px;
  line-height: 28px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 0 10px;
  width: 250px;
  box-sizing: border-box;
  position: relative;
  top: -3px;
}
.maLeftT-m{
  margin-top: 15px;
}
#save{
  color: #fff;
  background: #cc73cf;
  width: 100px;
  height: 40px;
  border:1px solid #e2e2e2;
  margin-top: 50px;
  border-radius:4px;
}
.mabox{
  position: absolute;
  top: 145px;
  left: 550px;
}
.flex-style1{
  display:flex;
  align-items: center;
}
.label-style1{
  margin-right: 20px;
  width: 60px;
  white-space: nowrap;
}
#mini-program-appid,#mini-program-page,#mini-program-spare{
    height: 28px;
    line-height: 28px;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    padding: 0 10px;
    box-sizing: border-box;
    width:230px;
    margin-right: 10px;
}
#webUrl,#miniProgram{
  margin-right:60px;cursor:pointer
}
@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1530847932969'); /* IE9*/
  src: url('iconfont.eot?t=1530847932969#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAWAAAsAAAAACCgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADMAAABCsP6z7U9TLzIAAAE8AAAARAAAAFZW7kgdY21hcAAAAYAAAABlAAABnM71alFnbHlmAAAB6AAAAaAAAAHwymJrZ2hlYWQAAAOIAAAALwAAADYR6Ut0aGhlYQAAA7gAAAAcAAAAJAfeA4VobXR4AAAD1AAAABAAAAAQD+kAAGxvY2EAAAPkAAAACgAAAAoBbgC4bWF4cAAAA/AAAAAfAAAAIAETAF1uYW1lAAAEEAAAAUUAAAJtPlT+fXBvc3QAAAVYAAAAKAAAADuf6EfWeJxjYGRgYOBikGPQYWB0cfMJYeBgYGGAAJAMY05meiJQDMoDyrGAaQ4gZoOIAgCKIwNPAHicY2Bk/sU4gYGVgYOpk+kMAwNDP4RmfM1gxMjBwMDEwMrMgBUEpLmmMDgwVDwzZG7438AQw9zA0AAUZgTJAQAnpQyfeJzFkNEJwCAMRC/GFikFF+mPUDpQvzqCE2cNm0R/nMCTF5PjICKADQArlxIB+kAwveqS+4zD/YhH56Qn6F0lS2lt6kzkieQdW5J2LBOtWz3r9HqPyf67DvSJkjvmS+kg/KENEHMAAAB4nE2PQUsCQRTH583srGvparu6q2vq6pZTWAuZGRTtiklQdAg6JR3sKlQHD106eAk6dOgaEpEEfYdIolP0DbxGfYgOZk2tRcPjz3tvfvD/P0QR+nwh9ySGVDSF5lAVbSEEYh6yMk5ChhVtnIdohkb1iEyYxTI+K2uTFdCzYkQrlIo5XfSJIZAhBfOZQonZmMFC0cHLUNCSAPGEsa1MjivkHEZiLHUy2MAdiKat8ZAzO1ifcSMFU5WOAooSV5QzSaRUwlgIybCva37qHxEHNzRkRO/T0zgNgTgzNneCZkLZOy0eJCd1P0CrBWrClG/dMWOM17GhqUrcFw5KMSNoTUTg6G00pgaSuVfEH+G3PpIH4qJRpCOL3ymDLwW6A4s2EBsYb/goA/m3x6jbp7Tf9bTTE4Rex9NqDeNa9UeJ80d0+/3nP6TTw8Yvw5VnwDzDA3ki5WEC7srNPD/P0ksjmiwXXiyZuhYmS+51fffCrZQFoVxxr3brl6tOVZAqh43BXaPZbMBao4nvnFX6/d2u19vusOdo++N9SDQ5jb4ABJFqs3icY2BkYGAAYra7Qdfj+W2+MnCzMIDA9VTmPQj6/34WBmYHIJeDgQkkCgAd4QnCAHicY2BkYGBu+N/AEMPCAAJAkpEBFbAAAEcKAm0EAAAAA+kAAAQAAAAEAAAAAAAAAAB2ALgA+AAAeJxjYGRgYGBhCGRgZQABJiDmAkIGhv9gPgMAERIBcQB4nGWPTU7DMBCFX/oHpBKqqGCH5AViASj9EatuWFRq911036ZOmyqJI8et1ANwHo7ACTgC3IA78EgnmzaWx9+8eWNPANzgBx6O3y33kT1cMjtyDRe4F65TfxBukF+Em2jjVbhF/U3YxzOmwm10YXmD17hi9oR3YQ8dfAjXcI1P4Tr1L+EG+Vu4iTv8CrfQ8erCPuZeV7iNRy/2x1YvnF6p5UHFockikzm/gple75KFrdLqnGtbxCZTg6BfSVOdaVvdU+zXQ+ciFVmTqgmrOkmMyq3Z6tAFG+fyUa8XiR6EJuVYY/62xgKOcQWFJQ6MMUIYZIjK6Og7VWb0r7FDwl57Vj3N53RbFNT/c4UBAvTPXFO6stJ5Ok+BPV8bUnV0K27LnpQ0kV7NSRKyQl7WtlRC6gE2ZVeOEXpc0Yk/KGdI/wAJWm7IAAAAeJxjYGKAAC4G7ICFkYmRmZGFkZWBsYK1KDElM581CEQyMAAALBEEpA==') format('woff'),
  url('iconfont.ttf?t=1530847932969') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('iconfont.svg?t=1530847932969#iconfont') format('svg'); /* iOS 4.1- */
}
.iconfont {
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-radio:before { content: "\e631"; }
.icon-Radio:before { content: "\e60f"; }