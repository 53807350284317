// member-rule-form
.member-rule-form {
  background-color: transparent;
  padding: 0;
}

#form-member-rule-content {
  .member-rule-title {
    width: 20%;
  }
  .member-rule-level {
    width: 50%;
  }
  .member-rule-discount {
    width: 20%;
  }
  .member-rule-action {
    width: 10%;
  }
  .level-container {
    @extend %inline-block;
  }
}

/* member rule form */
#form-member-rule-content {
  padding-bottom: 60px;
}
#member-rule-form .member-rule-table {
  width: 100%;
  min-width: 900px;
  border-collapse: collapse;
}
.member-rule-table .tr {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.member-rule-table .tr:nth-child(even) {
  background-color: #f8f8f8;
}
.member-rule-table .tr:nth-child(odd) {
  background-color: #fff;
}
.member-rule-table .tr.thead {
  background-color: #ebebeb;
}
.member-rule-table .td {
  float: left;
  width: 200px;
  height: 50px;
  text-align: center;
  display: inline-block;
}
.member-rule-table .level {
  width: 360px;
}
.member-rule-table .btn {
  width: 10%;
}
.member-rule-table input {
  width: 55%;
  height: 28px;
  line-height: 26px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 0 10px;
  font-size: 12px;
}

.member-rule-table .line {
  width: 45px;
}
.member-rule-table .line span {
  border-top: 1px solid #999;
  display: block;
}

.member-rule-ops {
  margin-top: 20px;
}
.ops-wrap {
  text-align: center;
  overflow: hidden;
  margin-bottom: 10px;
}
.ops-add {
  float: right;
  font-size: 12px;
}
.ops-save {
  font-size: 12px;
  color: #fff;
  background-color: #cc73cf;
  display: inline-block;
  padding: 10px 20px;
  margin:0 auto;
}
.ops-save:active {
  background-color: #86339a;
}


