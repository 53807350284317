
#goods-size-content {
  display: inline-block;
  vertical-align: middle;
}

// 库存页面
.inventory-add-btn {
  margin: 10px 40px;
}

