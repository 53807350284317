#body {
  overflow: hidden;
  min-width: 1000px;
}

.sidebar {
  width: 220px;
  float: left;
  font-size: 14px;

  li {
    margin-bottom: 1px;
    background-color: #fff;
  }

  a {
    color: #333;
    height: 50px;
    line-height: 50px;
    display: block;
    padding-left: 40px;
    background-color: #f8f8f8;
    position: relative;

    .arrow {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 7px;
      height: 11px;
      margin-top: -5px;
      background: url(../images/arrow.png) no-repeat 0 -11px;
    }

    &:hover {
      color: #fff;
      background-color: #cc73cf;

      .arrow {
        background-position: 0 0;
      }
    }

    &:focus {
      z-index: 1;
    }
  }

  .questing {
    margin-top: 20px;
    display: none;
  }

  .questing-img {
    margin-top: 20px;
    display: none;
  }
}

// 侧边栏
%sidebar-line {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 4px;
  background-color: #cc73cf;
}

%current-view {

  &,
  &:hover {
    cursor: pointer;
    color: #cc73cf;
    background-color: #fff;

    .arrow {
      background: url(../images/arrow-current.png) no-repeat 0 0;
    }

    position: relative;

    &:after {
      @extend %sidebar-line;
    }
  }
}

#page-view-keliu .current-view {
  @extend %current-view;
}

@each $page, $front in (
        demo:demo,
        memberCount:memberCount,
        wei_menu:wei_menu,
        order: order,
        sorder: order,
        user: member,
        coupon: coupon,
        coupons:couponCount,
        scoupon: coupon,
        tixian: tixian,
        icview: icview,
        privilege: tequan,
        goods: inventory,
        branch: shop,
        sshop: shop,
        system: system,
        worklist: task,
        yunying: yunying,
        nopurchase: nopurchase,
        activity: activity,
        backshop: backshop,
        purchasefollow:buy,
        recommend:recommend,
        personal: personal,
        promotion:promotion,
        festival:festival,
        renren:renren,
        union:union,
        worker:worker,
        service:service,
        evaluate:evaluate,
        concerned:concerned,
        package:package,
        reply:reply,
        pricerule:pricerule,
        focus_register:focus_register,
        appointment:appoint
) {
  .page-view-#{$page} .view-#{$front} a {
    @extend %current-view;
  }
}

.main {
  overflow: hidden;
  padding: 0 30px;
  position: relative;
}

%no-sidebar {
  .sidebar {
    width: 0;
    overflow: hidden;
  }
}

%no-header {
  #header {
    height: 0;
    overflow: hidden;
  }
}

%full-main {
  .main {
    padding: 0 10px;
  }
}

.no-sidebar {
  @extend %no-sidebar;
  @extend %full-main;
}

.no-header {
  @extend %no-header;
}

.fullscreen {
  @extend .no-header;
  @extend .no-sidebar;
}