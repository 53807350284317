#page-revenue{
    .list-revenue-main{
        margin-bottom: 200px;
    }
    .list-revenue-up{
        margin-bottom: 200px;
    }
    .form-group{
        line-height: 30px;
    }
    .table{
        min-height: 500px;
    }
    .table th{
        position: relative;
    }
    .table th ul{
        z-index: 1;
        list-style: none;
        width: 100%;
        text-align: center;
        box-shadow: 0 0 10px #bcbcbc;
        padding: 0;
        position: absolute;
        top:42px;
        display: none;
        font-size: 14px;
        font-weight: 400;
        background: white;
    }
    .the ul li:hover{
        background: #cc73cf;
        color: white
    }
    .hover ul li:hover{
        background: #cc73cf;
        color: white
    }
    .table .active ul{
        display: block;
    }
    .table .active ul li{
        font-size: 12px;
        height: 30px;
        line-height: 30px;
    }
    .revenue-fuceng {
        width: 400px;
        border-radius: 3px;
        background: white;
        position: absolute;
        left: 50%;
        margin-left: -310px;
        top: 50px;
        z-index: 11;
        display: none;
        .button-quit{
            margin-top: 190px;
            margin-bottom: 10px;
        }
        .pay-outer-quit,
        .revenue-outer-quit,
        .pay-quit{
            cursor: pointer;
            margin-left: 170px;
            background: white;
            border: 1px solid #e0e0e0;
            border-radius: 3px;
            font-size:14px;
            height: 28px;
            padding:0 15px
        }
        .fuceng-p{
            font-size: 16px;
            margin: 15px 0 0 23px;
        }
        .shop-pay-img{
            width: 140px;
            height: 140px;
        }
        .div-img{
            position: absolute;
            left: 130px;
            font-size: 12px;
        }
        .width-275{
            width: 275px;
        }
        .button-next{
            margin-top: 30px;
            margin-bottom: 10px;
        }
        .pay-outer-next,
        .revenue-outer-next{
            cursor: pointer;
            margin-left: 20px;
            background: #e0e0e0;
            border: 1px solid #e0e0e0;
            border-radius: 3px;
            font-size:14px;
            height: 28px;
            padding:0 15px
        }
    }
    .revenue-fuceng-four{
        position: fixed;
        top:0;
        width: 600px;
        margin-left: -300px;
        padding: 20px;
        .close_img{
            position: absolute;
            top: 10px;
            right:10px;
        }
    }
    .clear{
        clear: both;
    }
    .inline{
        background: #f8f8f8;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        width: 48.6%;
        height: 180px;
        margin-bottom: 20px;
        .revenue-user-head{
            padding-left: 25px;
            height: 50px;
            line-height: 50px;
            background: #ebebeb;
            font-size: 14px;
            color: #333
        }
        .revenue-user-body{
            padding-top: 30px;
            height: 130px;
            padding-left: 25px;
            p{
                color: #878787;
                font-size: 14px;
            }
        }
        .revenue-user-foot{
            margin-top: 10px;
            font-size: 30px;
            line-height: 45px;
            span{
                margin-right: 80px;
            }
            button{
                font: inherit;
                font-size: 12px;
                line-height: 28px;
                vertical-align: middle;
                padding: 0 20px;
                border: 1px solid #878787;
                border-radius: 4px;
                color: #666;
                background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
                cursor: pointer;
                height: 30px;
                width: 100px;
                float: right;
            }
        }
    }
    .inline:nth-child(2n+1){
        float: left;
    }
    .inline:nth-child(2n){
        float: right;
    }
    .remark-td{
        position: relative;
    }
    .remark-td-div{
        width: 350px;
        border-radius: 4px;
        position: absolute;
        box-shadow: 0 0 10px #bcbcbc;
        top: 0;
        left: -350px;
        display: none;
    }
    .remark-td-head{
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        background: #ebebeb;
    }
    .remrak-td-text{
        padding: 10px 10px 0 10px;
    }
    .remark-td-button{
        margin-bottom: 10px;
        button{
            width: 56px;
            height: 28px;
            border: 1px solid #ebebeb;
            border-radius: 4px;
        }
        .remark-td-quit-up,
        .remark-td-quit{
            cursor: pointer;
            background: white;
            margin: 0 10px 0 213px;
        }
        .remark-td-next-up,
        .remark-td-next{
            background: #ebebeb;
        }
    }
}
.submit_img {
    display: none;
    width: 400px;
    height: 450px;
    position: fixed;
    border: 1px solid #e0e0e0;
    background: #fff;
    left: 50%;
    margin-left: -200px;
    border-radius: 4px;
    top: 70px;
    z-index: 11;
    padding: 20px;
    box-sizing: border-box;
    .upload_empty-text{
        width: 100%;
        text-align: center;
        background: rgba(0,0,0,0.1);
        position: absolute;
        bottom: 0;
    }
    .button_left-quit {
        cursor: pointer;
        margin-left: 20px;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        font-size: 14px;
        height: 28px;
        padding: 0 10px;
    }
    .button_right-certain {
        cursor: pointer;
        margin-left: 10px;
        background: #e0e0e0;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        font-size: 14px;
        height: 28px;
        padding: 0 10px;
    }
}

