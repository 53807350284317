// 备注运营 layer start

.beizhu-layer {
  position: fixed;
  display: none;
  top: 130px;
  bottom: 2px;
  right: 0;
  width: 400px;
  overflow: auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 16px 1px #878787;
  height: auto !important;
}

.bz-kehu {
  background: #e0e0e0;
  padding: 15px;
}

.bz-goods{
  margin-top: 10px;
}

.kehu-sp-time {
  text-align: right;
  padding-top: 10px;
  padding-right:15px;
  background: rgb(235,235,235);
}

.bz-sp {
  padding: 15px 20px;
  font-size: 0;
  background-color: #ebebeb;
  border-bottom :1px solid #fff;
  li {
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
  }

  .bz-sp-img {
    margin-right: 10px;
    width: 62px;
    height: 62px;
    border: 1px solid rgb(151,151,151);
    img {
      width: 100%;
    }
  }
}

.bz-sp-name {
  width: 198px;
  display: inline-block;
  white-space:nowrap; 
  overflow:hidden;
  text-overflow:ellipsis;
  vertical-align: middle;
}

.bz-fk {
  margin:10px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  .fk-top {
    width: 100%;
    td {
      padding: 10px 16px;
      border-bottom: 1px solid #e0e0e0;
    }
    background-color: #f8f8f8;
    .fk-fk {
      display: inline-block;
      background: url(../images/checkbox-selected.png);
      width: 13px;
      height: 13px;
      border: 1px solid rgb(159,159,164);
      border-radius: 1px;
      vertical-align: middle;
      margin-right: 4px;
    }
    .fk-wbt {
      text-align: right;
    }
  }

  .fk-textarea {
    display: block;
    padding: 10px 16px;
    box-sizing: border-box;
    width: 100%;
    height: 8em;
    border: none;
    color: #aaa;
    font-size: 14px;
  }
  .fk-bottom {
    margin: 10px 0px;
    width: 96.4%;
    td {
      padding: 2px 16px;
    }
    .fk-cz {
      display:inline-block;
      width: 100px;
    }
    .fk-baocun {
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      padding: 2px 0;
      background: rgb(235,235,235);
    }
  }
}

.bz-hf {
  background: rgb(246,246,246);
  margin-top: 4px;
  padding: 10px 20px;
  color: #878787;
  .hf-time {
    margin-top: 4px;
    overflow: hidden;
  }
  .hf-time-day {
    float: right;
    margin-left: auto; 
  }
  .hf-fk {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    .hf-fk-cz-wrapper {
      text-align: left;
    }
    .hf-fk-detail {
      text-align: left;
      padding-bottom: 10px;
      color: #333;
    }
    pre {
      white-space: pre-wrap;
    }
    .hf-fk-bianji {
      width: auto;
      vertical-align: bottom;
    }
  }
}

#yunying-form-wx{
  .form-group{
    line-height: 30px;
  }
  .table{
    min-height: 500px;
  }
  .table table{
    width: 100%;
    text-align: center;
  }
  .table tr:nth-child(2n){
    background: #f8f8f8;
  }
  .table tr:hover{
    background: #e6e6e6
  }
  .table tr{
    font-size: 12px;
  }
  .table tr td{
    padding: 14px 5px;
    vertical-align: middle;
    line-height:1.5;
    word-break:break-all;
  }
  .table thead{
    height: 42px;
  }
  .table th{
    height: 42px;
    line-height: 42px;
    color: #505050;
    font-size: 12px;
    font-weight: 700;
    background: #ebebeb;
    text-align: center;
    position: relative;
  }
  .table th ul{
    z-index: 1;
    list-style: none;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 10px #bcbcbc;
    padding: 0;
    position: absolute;
    display: none;
    font-size: 14px;
    font-weight: 400;
    background: white;
  }
  .the ul li:hover{
    background: #cc73cf;
    color: white
  }
  .hover ul li:hover{
    background: #cc73cf;
    color: white
  }
  .table .active ul{
    display: block
  }
  .table .active ul li{
    font-size: 12px;
  }
   .activity-city-th, .activity-sname-th, .activity-stype-th, .activity-snum-th {
    width: 25%;
    height: 42px;
    line-height: 42px;
    color: #505050;
    font-size: 14px;
    font-weight: 700;
    background: #ebebeb;
    text-align: center;
    position: relative
  }

   #activity-form-shop {
    width: 100%;
    min-height: 480px
  }

   #activity-form-shop table {
    width: 100%
  }

   .activity-tbody-td {
    width: 25%;
    height: 42px;
    line-height: 42px;
    font-size: 12px;
    text-align: center;
    position: relative
  }

   .activity-city-zd img {
    position: absolute;
    top: 6px;
    left: 5px
  }

   .activity-city-all img {
    position: absolute;
    top: 6px;
    left: 5px
  }

   .activity-tbody-td img {
    position: absolute;
    top: 16px;
    left: 10px
  }

   .activity-shop-number, .activity-good-number {
    width: 286.5px;
    height: 50px;
    line-height: 50px;
    background: transparent;
    text-align: center;
    position: relative
  }

   .activity-shop-number img, .activity-good-number img {
    position: absolute;
    top: 16px;
    left: 10px
  }

   .add-shop-num, .add-good-num {
    color: #cc73cf;
    margin-left: 20px;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
    width: 56px;
    margin-top: 12px;
  }

   .activity-shai {
    width: 40%;
    margin-left: 30%;
    text-align: center
  }

   .snake ul {
    display: none
  }

   .skit ul {
    display: block
  }

   #activity-form-good {
    width: 100%;
    min-height: 450px
  }

   #activity-form-good table {
    width: 100%
  }

   .good-tr td {
    padding: 10px 0px;
    text-align: center;
    font-size: 12px
  }

   .good-tr>td:nth-child(1), .good-tr>th:nth-child(1) {
    width: 10%
  }

   .good-tr>td:nth-child(2), .good-tr>td:nth-child(4), .good-tr>td:nth-child(5), .good-tr>th:nth-child(2), .good-tr>th:nth-child(4), .good-tr>th:nth-child(5) {
    width: 21%
  }

   .good-tr>td:nth-child(3), .good-tr>th:nth-child(3) {
    width: 27%
  }

   .good-td {
    position: relative
  }

   .Good-img {
    position: absolute;
    left: 10px;
    top: 15px
  }

   .good-up-img {
    position: relative;
    top: 5px;
    left: -20px
  }

   .form-group>label {
    font-size: 12px;
    line-height: 30px
  }

   #radio-img {
    margin-left: 12.5px
  }

   textarea {
    max-height: 100px;
    max-width: 294px
  }

   .del-fuceng {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 1400px;
    background: rgba(0,0,0,0.4);
    z-index: 1
  }

   th.thp form {
    position: absolute;
    top: 42px;
    left: 0;
    display: none;
    width: 300px;
    height: 108px;
    background-color: #fff;
    box-shadow: 0 0 10px #bcbcbc
  }

   th.thp input {
    margin: 14px 0;
    font-size: 13px;
    color: #333
  }

   th.thp .lso-time-start, th.thp .lso-time-end {
    width: 120px;
    height: 30px;
    text-align: center;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #e8e8e8
  }

   th.thp .lso-time-start {
    margin-right: 16px
  }

   th.thp .form-p {
    text-align: right;
    padding-right: 17px
  }

   th.thp .lso-time-cancel, th.thp .lso-time-confirm {
    border: none;
    background-color: #fff;
    cursor: pointer
  }

  .op-btn{
  font: inherit;
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 20px;
  border: 1px solid #878787;
  border-radius: 4px;
  color: #666;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
  cursor: pointer;
  }
  .goods{
    color: #cc73cf;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    line-height: 28px;
    padding: 0 10px;
    text-align: center;
  }
}

