#page-login {
  min-width: 1100px;
}

.login {
  background: url(../images/background.png) no-repeat 0 100%;
  position: relative;
  min-height: 500px;
  width: 1100px;
  margin: 0 auto;
  text-align: center;

  .serveLogin {
    position: absolute;
    top: 496px;
    right: 0px;

    a {
      color: #cc73cf;
    }

    :hover {
      color: #F00;
      text-decoration: none;
      cursor: hand;
    }

  }

  &-box {
    width: 386px;
    border: 2px solid #dbdbdb;
    background-color: #fff;
    position: absolute;
    top: 100px;
    right: -20px;
    display: block;
  }

  &-head {
    height: 54px;
    line-height: 54px;
    font-size: 0;
    color: #666;
    background-color: #f4f8fc;
    border-bottom: 1px solid #e0e1e2;
    li {
      font-size: 16px;
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: 50%;
      text-align: center;
    }
    a {
      display: block;
      width: 80%;
      margin: 0 auto;
      border-bottom: 1px solid transparent;
    }
    li.current a {
      color: #000;
      border-bottom-color: #333;
    }
  }

  &-body {
    width: 80%;
    margin: 35px auto 50px;
    display: none;

    a,
    a:visited,
    a:hover,
    a:active {
      color: #fff;
    }
  }

  .btn-helper {
    font: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

}

.login-feedback {
  float: left;
  margin: 15px 0;
  text-align: left;
  font-size: 14px;
  visibility: hidden;
}

.login-submit {
  display: block;
  clear: both;
  height: 45px;
  line-height: 45px;
  border-radius: 4px;
  color: #fff;
  background-color: #cc73cf;
  font-size: 18px;
  text-align: center;
  opacity: 0.6;
  cursor: pointer;
}

.login-submit:hover,
.login-submit:active {
  background-color: #86339a;
}

#login-user {
  display: block;
}

#login-randcode {
  h2 {
    font-size: 14px;
  }
  .username {
    font-size: 16px;
  }
}

#login-shop {
  li {
    margin: 10px auto;
    height: 45px;
    line-height: 45px;
    color: #666;
    background-color: #eee;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    &.selected {
      cursor: default;
      color: #ed6d00;
    }
  }
  input {
    display: none;
  }
}

.form_tip{
  float: left;
  margin-left: 60px;
  text-align: left;
  font-size: 14px;
  visibility: hidden;
}

.warning {
  float: left;
  color: #cc73cf;
  visibility: visible;
}

.requesting {
  cursor: wait;
}


.login-resend {
  margin: 15px 0;
  float: right;
  height: 30px;
  line-height: 28px;
  box-sizing: border-box;
  border: 1px solid #cc73cf;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  cursor: default;
}

.login-resend-disabled {
  border: 1px solid #c1c1c1;
  color: #c1c1c1;
}

#login-shop-list label {
  display: block;
}

.serveblogin {
  display: none;

  .serveblogin-close {
    margin: 20px 0 20px 260px;

    a {
      cursor: pointer;
    }
  }

  input {
    width: 290px;
    height: 40px;
    display: block;
    border-radius: 10px;
    border: 1px solid #cc73cf;
    margin: 20px 0 20px 50px;
    outline: none;
    padding-left: 10px;
  }

  .code-btn1 {
    display: block;
    position: absolute;
    right: 34px;
    top: 127px;
    background: #cc73cf;
    border: 1px solid #cc73cf;
    height: 40px;
    border-radius: 10px;
    color: #fff;
    opacity: 0.6;
    cursor: pointer;
    outline: none;
  }

  .service-resend-disabled
  {
    background-color: #999
  }

  .code-btn1:hover,.code-btn1:active {
    background-color: #86339a;
  }
  .service-resend-disabled:hover,.service-resend-disabled:active
  {
    background-color: #999
  }
}

.serve-login {
  display: block;
  width: 80px;
  height: 45px;
  line-height: 45px;
  border-radius: 4px;
  color: #fff;
  background-color: #cc73cf;
  font-size: 18px;
  text-align: center;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 10px;
  margin: 70px 0 40px 150px;

}

.serve-login:visited,
.serve-login:hover,
.serve-login:active {
  color: #fff;
}
.serve-login:hover, .serve-login:active {
  background-color: #86339a;
}

