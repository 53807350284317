.upload {
  &-wrap {
    position: relative;
    width: 120px;
    height: 120px;
    display: inline-block;
    margin: 10px;
    border: 1px solid #e0e0e0;
    background: url(../images/upload--add.jpg) no-repeat 50% 50%/cover;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  &_progress {
    position: absolute;
    bottom: 0;
    height: 4px;
    transition: all .5s linear;
  }

  &--progress .upload_progress {
    background-color: green;
  }
  &--fail .upload_progress {
    background-color: red;
  }
  &--done .upload_progress {
    background-color: green;
    width: 100%;
  }
  &--loaded .upload_progress {
    background-color: green;
    width: 100%;
    opacity: 0;
  }

  &_reset {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -12px;
    right: -12px;
    background: url(../images/upload--reset.png) no-repeat 50% 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s linear;
    cursor: pointer;
  }
  &--have-image:hover .upload_reset {
    pointer-events: auto;
    opacity: 1;
  }
  &--have-image .upload_empty-text{
    display: none;
  }
}
