$background1:#fff;
@mixin flex1{
    display:flex;
}
@mixin flex2{
    display:flex;
    justify-content: flex-end;
}
.system_payment_style{
    background:$background1;
    @include flex1;
    width:90%;
    box-sizing: border-box;
    padding:30px 30px;
    p{
        margin-right:30px;
        display:flex;
        align-items: center;
        img{
            margin-left:15px;
        }
    }
}
.submit-btn-box{
    @include flex2;
}
.submit-btn{
    margin-right: 100px;
    margin-top: 30px;
}
.remark-info{
    display:inline-block;
    font-size:12px;
    color:#999;
    margin-top:10px;
}
.share-optimise{
    width: 350px;
    height: 254px;
    position: absolute;
    font-size: 12px;
    line-height: 30px;
}
body .border-color {
    border-color: #cc73cf;
}