// 请选择门店
.empty-shop-k {
  background: rgb(248,248,248) url(../images/empty-shop-k.png) no-repeat 50% 110px;
}

%icon-checkbox-selected {
  border: 1px solid rgb(159,159,164);
  @extend %inline-block;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.icon-checkbox-selected {
  @extend %icon-checkbox-selected;
  background-image: url(../images/checkbox-selected.png);
}

.icon-checkbox-selected-noimage {
  @extend %icon-checkbox-selected;
}

.yunying-item-operate span {
  vertical-align: middle;
  margin-left: 6px;
}


.list-yunying ,
.list-content{
  table {
    border: 1px solid rgb(235,235,235);
  }

  .vertical-align-top {
    vertical-align: top;
  }

  tbody tr:nth-child(even) {
    &:hover {
      background-color: #f8f8f8;
    }
  }

  tbody tr:nth-child(odd) {
    &:hover {
      background-color: #fff;
    }
  }

  td {
    border: 1px solid rgb(235,235,235);
  }

  .yunying-item-detail {
    text-align: left;
    padding: 2px 16px;
    pre {
      white-space: pre-wrap;
    }
  }

  .yunying-item-huashu {
    width: 40%;
    text-align: left;
    padding: 2px 16px;
    pre {
      white-space: pre-line;
    }
  }

  .yunying-item-dianhua {
    width: 40%;
  }

  .yunying-item-dianhua-detail {
    td {
      border: 1px solid transparent;
    }

    tbody tr {
      cursor: default;
      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }

  .yunying-item-dianhua-beizhu {
    color: #cc73cf;
    cursor: pointer;
  }
}

.empty-content-no-branchid {
  height: 400px;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(../images/empty-shop-select.png);
}

#yunying-list-tel .empty-content {
  background-image: url(../images/empty-tel-remind.png);
}

%yunying-status {
  padding: 18px 18px 0 0;
  display: inline-block;
  vertical-align: middle;
  background-image: url(../images/yunying-status.png);
  background-repeat: no-repeat;
}
@for $i from 0 through 2 {
  .yunying-dianhua-status-#{$i} {
    @extend %yunying-status;
    background-position: 0 -1 * $i * 18px;
  }
}
 .group-box .content-group {
  margin-top: 14px;
  display: none;
}

