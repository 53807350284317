#page-evaluate{
  .list-evaluate-good{
    min-height: 500px;
    .shop-arrow{
      display: none;
    }
    th{
      position: relative;
    }
    th ul{
      z-index: 1;
      list-style: none;
      width: 100%;
      text-align: center;
      box-shadow: 0 0 10px #bcbcbc;
      padding: 0;
      position: absolute;
      top:42px;
      display: none;
      font-size: 14px;
      font-weight: 400;
      background: white;
    }
    ul li:hover{
      background: #cc73cf;
      color: white
    }
    .active ul{
      display: block;
    }
    .active ul li{
      font-size: 12px;
      height: 30px;
      line-height: 30px;
    }
  }
}