$title-color: #505050;

#header {
  height: 70px;
  border-bottom: 2px solid #91419d;
  overflow: hidden;
  box-sizing: border-box;
}

#logo {
  float: left;
  margin-right: 30px;
  _display: inline;
  width: 220px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.header-user {
  padding-top: 27px;
  padding-right: 40px;
  overflow: hidden;
  h1 {
    float: left;
    color: $title-color;
    #page-login & {
      display: none;
    }
  }
}

.header-contact {
  float: right;
  dt, dd {
    float: left;
    margin-top: 1px;
    color: $title-color;
  }
  dt {
    margin-right: 2px;
    margin-left: 10px;
  }
}

#logout {
  float: right;
  margin-left: 30px;
  #page-login & {
    display: none;
  }
}

#logout a, .header-help a {
  color: #cc73cf;
  &:hover {
    color: #86339a;
  }
}

.header-help {
  float: right;
  margin-left: 30px;
}

